import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BuyerNavButton } from '../Components/Buttons';
import BuyerHeader from '../Components/Header/BuyerHeader';
import AddRequest from '../Components/UnlockRequest/AddRequest';
import RequestTable from '../Components/UnlockRequest/RequestTable';
import '../Styles/RepairSheet/RepairSheet.css';
import '../Styles/Unlocking/styles.css';

function UnLocking() {
  const [selectedTab, setSelectedTab] = useState('add-request');
  const [fetching, setFetching] = useState(true);
  const [stats, setStats] = useState({
    inProgress: 0,
    success: 0,
    rejected: 0,
  });
  useEffect(() => {
    (async () => {
      setFetching(true);
      try {
        let shopId = JSON.parse(localStorage.getItem('user'))?._id;
        const resp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/unlocking/status?shop=${shopId}`
        );
        let stats = resp.data.data.reduce((acc, item) => {
          acc[item._id] = item.count;
          return acc;
        }, {});
        setStats((st) => ({ ...st, ...stats }));
      } catch (er) {
      } finally {
        setFetching(false);
      }
    })();
  }, []);
  return (
    <>
      <BuyerHeader navs='6' />
      <div className='unl-container'>
        <div
          className='buttons-tab-container'
          style={{
            display: 'flex',
            gap: '10px',
            marginBottom: '30px',
            flexWrap: 'wrap',
          }}
        >
          <BuyerNavButton
            text='Add Phone Specification'
            bgcolor={selectedTab === 'add-request' ? '#FF7C5C' : '#F5F5F5'}
            color={selectedTab === 'add-request' ? '#fff' : '#000'}
            onClick={() => setSelectedTab('add-request')}
          />
          <BuyerNavButton
            text={`In Progress Requests (${stats.inProgress})`}
            bgcolor={selectedTab === 'inProgress' ? '#FF7C5C' : '#F5F5F5'}
            color={selectedTab === 'inProgress' ? '#fff' : '#000'}
            onClick={() => setSelectedTab('inProgress')}
          />
          <BuyerNavButton
            text={`Success Requests (${stats.success})`}
            bgcolor={selectedTab === 'success' ? '#FF7C5C' : '#F5F5F5'}
            color={selectedTab === 'success' ? '#fff' : '#000'}
            onClick={() => setSelectedTab('success')}
          />
          <BuyerNavButton
            text={`Rejected Requests (${stats.rejected})`}
            bgcolor={selectedTab === 'rejected' ? '#FF7C5C' : '#F5F5F5'}
            color={selectedTab === 'rejected' ? '#fff' : '#000'}
            onClick={() => setSelectedTab('rejected')}
          />
        </div>
        {selectedTab === 'add-request' && <AddRequest />}
        {selectedTab !== 'add-request' && <RequestTable type={selectedTab} />}
      </div>
    </>
  );
}

export default UnLocking;
