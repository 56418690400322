import React, { useState, useEffect } from "react";
import "../Styles/Modal.css"; // Import your CSS for modal styling
import { ReactComponent as CloseIcon } from "../Components/Header/svgs/close.svg";
import Input from "./Input";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function DiscountModal({ repairId, isOpen, onClose, children }) {
  const user = useSelector((state) => state.user.user);
  const [formData, setFormData] = useState({
    discount: "",
  });
  const loggedInUserShopId =
    JSON.parse(localStorage.getItem("user"))?._id ?? undefined;
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (event) => {
    if (!formData.discount) {
      toast.error("Please add discount");
      return;
    }
    const body = { ...formData, id: repairId };
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/api/v1/repair/addDiscount?shop=${loggedInUserShopId}`,
      body
    );
    if (response.status == 200) {
      toast.success(response.data.message);
      // onClose();
    } else {
      toast.error(response.data.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="Upper-modal-row">
          <h2>Discount</h2>
          {/* <CloseIcon onClick={onClose} /> */}
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="row">
            <div className="input-boxer" style={{ width: "100%" }}>
              <input
                type="number"
                name="discount"
                placeholder="Enter Discount"
                min={0}
                value={formData.discount}
                onChange={handleFormChange}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="columner">
            <button
              type="submit"
              className="modal-button"
              // onClick={() => {
              //   onClose();
              // }}
            >
              Apply
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DiscountModal;
