// LoadingIcon.jsx

import React from 'react';

const LoadingIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#FF7C5C"
  >
    <g
      transform="translate(20 20)"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    >
      <circle strokeOpacity=".5" cx="0" cy="0" r="18"></circle>
      <path d="M0-18V0" stroke="#fff">
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1.5s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 0 0;360 0 0"
        ></animateTransform>
      </path>
    </g>
  </svg>
);

export default LoadingIcon;
