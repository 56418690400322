import PrintJS from 'print-js';
import React from 'react';
import { ReactComponent as CloseIcon } from '../Components/Header/svgs/close.svg';
import '../Styles/Modal.css'; // Import your CSS for modal styling
import Print from './Print';

function RepairCheckoutModal({
  isOpen,
  onClose,
  showReceipt,
  searchedProducts,
  repairSheetReceipt,
  userName,
}) {
  const currentDate = new Date();

  // Format date as DD/MM/YYYY
  const formattedDate = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`;

  // Format time as HH:mm:ss
  const formattedTime = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

  if (!isOpen) return null;

  return (
    <div className='modal-overlay'>
      <div className='modal'>
        <div className='Upper-modal-row'>
          {showReceipt == false ? (
            <h2>Choose Payment Method</h2>
          ) : (
            <h2>Reciept</h2>
          )}
          <CloseIcon onClick={onClose} />
        </div>
        {showReceipt == false ? (
          <div className='row-modal-eir'></div>
        ) : (
          <>
            <div className='reciept-container'>
              <div className='reciept'>
                <h1>Freedom Phones</h1>
                <div className='reciept-row'>
                  <p>9 stall Street</p>
                  <p>#Invoice : {repairSheetReceipt.invoice}</p>
                </div>
                <div className='reciept-row'>
                  <p>Somerset</p>

                  <p>{formattedDate}</p>
                  <p>VAT NO: 450 014 056</p>
                </div>
                <div className='reciept-row'>
                  <p>BA16 0EN</p>
                  <p>{formattedTime}</p>
                  <p style={{ color: 'white' }}>#</p>
                </div>
                {searchedProducts.map((item) => (
                  <div className='reow-reciept'>
                    <p className='quantity'>Repair </p>
                    <p className='product'>{item.partName}</p>
                  </div>
                ))}

                <div className='reow-reciept'>
                  <p className='quantity'>Total Amount</p>
                  <p className='product'>{repairSheetReceipt.charges}</p>
                </div>

                <div className='reow-reciept'>
                  <p className='quantity'>Amount Deposit</p>
                  <p className='product'>
                    {repairSheetReceipt.isInitialDeposit
                      ? repairSheetReceipt.deposit
                      : repairSheetReceipt.finalDeposit}
                  </p>
                </div>
                <div className='reow-reciept'>
                  <h3 className='quantity'>Amount Due</h3>
                  <h3 className='product'>
                    {!repairSheetReceipt.isInitialDeposit
                      ? 0
                      : repairSheetReceipt.charges - repairSheetReceipt.deposit}
                  </h3>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <p style={{ margin: '5px auto' }}>{userName}</p>
                  <p style={{ margin: '5px auto' }}>
                    Thanks for shopping with us
                  </p>
                  <p style={{ margin: '5px auto' }}>www.freedomphones.co.uk</p>
                </div>
              </div>
            </div>
            <Print
              printReciept={() => {
                PrintJS({
                  printable: 'reciept-container',
                  type: 'html',
                  targetStyles: ['*'], // Apply styles to the entire document
                });
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default RepairCheckoutModal;
