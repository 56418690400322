import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import './selec.css';

function Select({
  options,
  onSelect,
  name,
  icon,
  style,
  isOpen,
  setIsOpen,
  onClick,
  clear,
  placeholder = '',
}) {
  const [selectedValue, setSelectedValue] = useState('');

  const handleOptionClick = (option) => {
    setSelectedValue(option.label);
    setIsOpen(false);
    onSelect(option.value);
  };
  useEffect(() => {
    if (clear) setSelectedValue('');
  }, [clear]);

  return (
    <div className='custom-select'>
      <button
        className='opt-button'
        onClick={() => {
          setIsOpen(!isOpen);
          onClick();
        }}
        style={style}
      >
        {icon} <p>{selectedValue || placeholder} </p>
        <AiOutlineCaretDown />
      </button>
      {isOpen && (
        <ul className='options'>
          {options.map((option) => (
            <li key={option.value} onClick={() => handleOptionClick(option)}>
              {option.label}{' '}
              {option.label == selectedValue && (
                <div className='bordercircle'></div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Select;
