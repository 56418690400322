import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { ReactComponent as BlackIcon } from '../../Images/blackclose.svg';
import '../../Styles/SelectOption/SelecOption.css';

import profile from '../../Images/dp.png';

export function CustomSelect({
  options,
  onSelect,
  name,
  icon,
  size = 'large',
  color = 'white',
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const handleOptionClick = (option) => {
    setSelectedValue(option.label);
    setIsOpen(false);
    onSelect(option.value); // Call the onSelect callback with the selected value
  };

  return (
    <div className='custom-select' style={{}}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        style={{
          ...(size === 'small' && {
            padding: '11px 20px',
          }),
        }}
      >
        {name === 'Select Staff' && selectedValue === '' ? (
          <p
            style={{
              ...(size === 'small' && { margin: 0 }),
            }}
          >
            {' '}
            {selectedValue || name}{' '}
          </p>
        ) : (
          <p
            style={{
              ...(size === 'small' && { margin: 0 }),
            }}
          >
            {' '}
            <img src={profile} />
            {selectedValue || name}{' '}
            <BlackIcon
              onClick={() => {
                setIsOpen(!isOpen);
                setSelectedValue('');
                onSelect('');
              }}
            />
          </p>
        )}
        <FaAngleDown />
      </button>
      {isOpen && (
        <ul className='options'>
          {options.map((option) => (
            <li key={option.value} onClick={() => handleOptionClick(option)}>
              <p>
                {' '}
                <img src={profile} /> {option.label}{' '}
              </p>{' '}
              {option.label !== selectedValue && (
                <span className='bordercircle'>Select</span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
export function CustomSelectTwo({ options, onSelect, name, icon }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const handleOptionClick = (option) => {
    setSelectedValue(option.label);
    setIsOpen(false);
    onSelect(option.value); // Call the onSelect callback with the selected value
  };

  return (
    <div className='custom-select'>
      <div className='csi' onClick={() => setIsOpen(!isOpen)}>
        <p> {selectedValue || name} </p>
        <FaAngleDown />
      </div>
      {isOpen && (
        <ul className='options'>
          {options.map((option) => (
            <li key={option.value} onClick={() => handleOptionClick(option)}>
              <p> {option.label} </p>{' '}
              {option.label !== selectedValue && (
                <span className='bordercircle'>Select</span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
