import React from "react";
import { render, Printer, Text } from "react-thermal-printer";
import { MButton } from "../Components/Buttons";
import { ReactComponent as PaidoutIcon } from "../Pages/Svg/paidout.svg";

export default function Print({ printReciept }) {
  return (
    <div style={{ margin: "0px auto" }}>
      <Printer type="epson" width={42}></Printer>
      <MButton
        text="Print"
        icon={<PaidoutIcon />}
        bgcolor="#FF7C5C"
        color="#fff"
        onClick={printReciept}
      />
    </div>
  );
}
