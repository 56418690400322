import React, { useEffect, useState } from 'react';
import { GrPowerReset } from 'react-icons/gr';
import { LuLogOut } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import logo from '../../Images/login.png';
import { ReactComponent as InvoiceIcon } from '../../Pages/Svg/invoice.svg';
import { ReactComponent as InvoiceBlackIcon } from '../../Pages/Svg/invoiceblack.svg';
import { ReactComponent as PrinterIcon } from '../../Pages/Svg/printer.svg';
import { ReactComponent as SalesIcon } from '../../Pages/Svg/sales.svg';
import { ReactComponent as SalesBlackIcon } from '../../Pages/Svg/salesblack.svg';
import { ReactComponent as SettingIcon } from '../../Pages/Svg/settings.svg';
import { ReactComponent as SettingBlackIcon } from '../../Pages/Svg/settingsb.svg';
import { ReactComponent as StocksIcon } from '../../Pages/Svg/stocks.svg';
import { ReactComponent as StocksBlackIcon } from '../../Pages/Svg/stocksblack.svg';
import { ReactComponent as TillIcon } from '../../Pages/Svg/till.svg';
import { ReactComponent as TillBlackIcon } from '../../Pages/Svg/tillblack.svg';
import '../../Styles/Header/Header.css';
import { Button, ButtonTags, BuyerNavButton, NavButton } from '../Buttons';
import DatePicker from '../DatePicker/Date';
import { ReactComponent as DateIcon } from './svgs/date.svg';
import { ReactComponent as Lock } from './svgs/lock.svg';
import { ReactComponent as RefreshIcon } from './svgs/refresh.svg';

import { getFormatDate } from '../../Functions/date';
import { ReactComponent as PrinterBlackIcon } from '../../Pages/Svg/printerb.svg';

function NavHeader({
  navs,
  toDate,
  setToDate,
  fromDate,
  setFromDate,
  paymentModeFilter,
  setPaymentModeFilter,
  handleResetDate,
}) {
  const [nav, setNav] = useState(navs);
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  useEffect(() => {
    if (window.location.pathname === '/sales') {
      setNav('1');
    } else if (window.location.pathname === '/stock') {
      setNav('2');
    } else if (window.location.pathname === '/invoices') {
      setNav('3');
    } else if (window.location.pathname === '/till') {
      setNav('4');
    } else if (window.location.pathname === '/printer') {
      setNav('5');
    } else if (window.location.pathname === '/settings') {
      setNav('6');
    }
  }, []);
  const printerere = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
    >
      <path d='M3.3335 0.5H13.3335V4.66667H3.3335V0.5Z' fill='white' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M1.66667 5.5C1.22464 5.5 0.800716 5.6756 0.488155 5.98816C0.175595 6.30072 0 6.72464 0 7.16667V11.3333C0 11.7754 0.175595 12.1993 0.488155 12.5118C0.800716 12.8244 1.22464 13 1.66667 13H2.5V15.5H14.1667V13H15C15.442 13 15.8659 12.8244 16.1785 12.5118C16.4911 12.1993 16.6667 11.7754 16.6667 11.3333V7.16667C16.6667 6.72464 16.4911 6.30072 16.1785 5.98816C15.8659 5.6756 15.442 5.5 15 5.5H1.66667ZM12.5 8H4.16667V13.8333H12.5V8Z'
        fill='white'
      />
    </svg>
  );
  function formatDateString(inputDateString) {
    const originalDate = new Date(inputDateString);

    const options = {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      weekday: 'long',
    };

    return new Intl.DateTimeFormat('en-US', options).format(originalDate);
  }

  return (
    <div className='header'>
      <div className='upper'>
        <img src={logo} />

        <div className='nav-container'>
          <Link to='/sales'>
            {' '}
            {navs == 1 ? (
              <NavButton
                text='Sales Report'
                icon={<StocksIcon />}
                bgcolor='#FF7C5C'
                color='#fff'
                active={true}
              />
            ) : (
              <NavButton
                text='Sales Report'
                icon={<StocksBlackIcon />}
                bgcolor='white'
                color='#000'
              />
            )}
          </Link>
          <Link to='/stock'>
            {' '}
            {navs == 2 ? (
              <NavButton
                text='Stock Report'
                icon={<SalesIcon />}
                bgcolor='#FF7C5C'
                color='#fff'
                active={true}
              />
            ) : (
              <NavButton
                text='Stock Report'
                icon={<SalesBlackIcon />}
                bgcolor='#fff'
                color='#000'
              />
            )}
          </Link>
          <Link to='/invoices'>
            {' '}
            {navs == 3 ? (
              <NavButton
                text='Invoices'
                icon={<InvoiceIcon />}
                bgcolor='#FF7C5C'
                color='#fff'
                active={true}
              />
            ) : (
              <NavButton
                text='Invoices'
                icon={<InvoiceBlackIcon />}
                bgcolor='#fff'
                color='#000'
              />
            )}
          </Link>
          <Link to='/home'>
            {navs == 4 ? (
              <NavButton
                text='Till Report'
                icon={<TillIcon />}
                bgcolor='#FF7C5C'
                color='#fff'
                active={true}
              />
            ) : (
              <NavButton
                text='Till Report'
                icon={<TillBlackIcon />}
                bgcolor='#fff'
                color='#000'
              />
            )}
          </Link>
          <Link to='printer'>
            {navs == 5 ? (
              <NavButton
                text='Printer'
                icon={<PrinterIcon />}
                bgcolor='#FF7C5C'
                color='#fff'
                active={true}
              />
            ) : (
              <NavButton
                text='Printer'
                icon={<PrinterBlackIcon />}
                bgcolor='#fff'
                color='#000'
              />
            )}{' '}
          </Link>
        </div>
      </div>
      {nav == 1 && (
        <div
          className='lower-nav'
          style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
        >
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <h4>Sales Report</h4>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Link to='/home'>
                <ButtonTags
                  text='Open Till'
                  icon={<Lock />}
                  bgcolor='#F3F3F3'
                  color='#000'
                />
              </Link>
              <Link to='/dashboard'>
                <ButtonTags
                  text='Dashboard'
                  icon={<RefreshIcon />}
                  bgcolor='#F3F3F3'
                  color='#000'
                />
              </Link>
            </div>
          </div>
        </div>
      )}
      {nav == 2 && (
        <div
          className='lower-nav'
          style={{
            alignItems: 'flex-start',
            flexDirection: 'row',
          }}
        >
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <h4>Stock Report</h4>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Link to='/home'>
                <ButtonTags
                  text='Open Till'
                  icon={<Lock />}
                  bgcolor='#F3F3F3'
                  color='#000'
                />
              </Link>
              <Link to='/dashboard'>
                <ButtonTags
                  text='Dashboard'
                  icon={<RefreshIcon />}
                  bgcolor='#F3F3F3'
                  color='#000'
                />
              </Link>
            </div>
          </div>
        </div>
      )}
      {nav == 3 && (
        <div className='lower-nav'>
          <>
            <span></span>
          </>
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <h4>Invoices</h4>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Link to='/home'>
                <ButtonTags
                  text='Open Till'
                  icon={<Lock />}
                  bgcolor='#F3F3F3'
                  color='#000'
                />
              </Link>
              <Link to='/dashboard'>
                <ButtonTags
                  text='Dashboard'
                  icon={<RefreshIcon />}
                  bgcolor='#F3F3F3'
                  color='#000'
                />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NavHeader;
