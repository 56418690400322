import axios from 'axios';
import debounce from 'lodash/debounce'; // Import debounce function
import React, { useEffect, useState } from 'react';
import NavHeader from '../Components/Header/NavHeader';
import Pagination from '../Components/Pagination';
import SearchInput from '../Components/Search';
import Spinner from '../Components/Spinner';
import '../Styles/StockReport/StockReport.css';

function StockReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [itemStock, setItemStock] = useState([]);
  const [totalStock, setTotalStock] = useState();

  const [searchItem, setSearchItem] = useState({
    barCode: '',
    vender: '',
    model: '',
    partName: '',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const startingIndex = (currentPage - 1) * itemsPerPage + 1;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const loggedInUserShopId =
    JSON.parse(localStorage.getItem('user'))?._id ?? undefined;

  useEffect(() => {
    fetchItemStock();
  }, [currentPage]);

  const debouncedFetchStockItems = debounce(() => {
    fetchItemStock();
  }, 500);

  const handleSearch = () => {
    debouncedFetchStockItems();
  };

  useEffect(() => {
    /* If we want to make search using search Button and we want data to be fetched automatically if search is empty
    if (
      searchItem.barCode == "" &&
      searchItem.vender == "" &&
      searchItem.model == "" &&
      searchItem.partName == ""
    ) {
      fetchItemStock();
    } */
    handleSearch();
  }, [searchItem]);

  const fetchItemStock = async () => {
    const pageNumber = currentPage;
    const recordsPerPage = itemsPerPage;
    try {
      const queryParams = [];

      // Include the search terms in the query parameters
      if (searchItem.barCode) {
        queryParams.push(`barCode=${searchItem.barCode}`);
      }
      if (searchItem.vender) {
        queryParams.push(`vender=${searchItem.vender}`);
      }
      if (searchItem.model) {
        queryParams.push(`model=${searchItem.model}`);
      }
      if (searchItem.partName) {
        queryParams.push(`partName=${searchItem.partName}`);
      }

      // Join the query parameters with '&' to form the complete query string
      const queryString = queryParams.join('&');

      if (queryParams.length > 0) {
        setCurrentPage(1);
      }

      // Construct the API URL with query parameters
      const apiUrl = queryString
        ? `/api/v1/stock?shop=${loggedInUserShopId}&${queryString}`
        : `/api/v1/stock?shop=${loggedInUserShopId}`;

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${apiUrl}`
      );
      setTotalStock(response.data.data.count);
      setItemStock(
        response.data.data.stockList.filter((el) => el.itemsInStock !== 0)
      );
    } catch (error) {
      console.error('Error while fetching item stock: ', error);
    }
  };

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      <NavHeader navs='2' />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='stock-page-container'>
          <div className='stocks-row'>
            <SearchInput
              placeholder='Search Bar Code'
              searchTerm={searchItem.barCode}
              onChange={(e) =>
                setSearchItem({ ...searchItem, barCode: e.target.value })
              }
            />
            <SearchInput
              placeholder='Search Brand'
              searchTerm={searchItem.vender}
              onChange={(e) =>
                setSearchItem({ ...searchItem, vender: e.target.value })
              }
            />
            <SearchInput
              placeholder='Search Model'
              searchTerm={searchItem.model}
              onChange={(e) =>
                setSearchItem({ ...searchItem, model: e.target.value })
              }
            />
            <SearchInput
              placeholder='Search Partname'
              searchTerm={searchItem.partName}
              onChange={(e) =>
                setSearchItem({ ...searchItem, partName: e.target.value })
              }
            />
            <button className='search-button' onClick={handleSearch}>
              Search
            </button>
          </div>
          <div className='stock-table'>
            {itemStock && itemStock.length > 0 ? (
              <>
                <table class='blueTable'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Barcode</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Partname</th>
                      <th>Color</th>
                      <th>Price</th>
                      <th>Stock</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemStock.map((item, index) => (
                      <tr key={item._id}>
                        <td>{index + startingIndex}</td>
                        <td>{item.barCode}</td>
                        <td>{item.vender}</td>
                        <td>{item.model}</td>
                        <td>{item.partName}</td>
                        <td>{item.color}</td>
                        <td>{item.price}</td>
                        <td>{item.itemsInStock}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <table class='blueTable'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Barcode</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Partname</th>
                      <th>Color</th>
                      <th>Price</th>
                      <th>Stock</th>
                    </tr>
                  </thead>
                </table>
                <div className='no-data'>
                  <p>No data available</p>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default StockReport;
