import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { AiOutlineInfo } from 'react-icons/ai';
import Authenticate from '../Components/AuthenticateUser';
import { Button, BuyerNavButton } from '../Components/Buttons';
import DatePicker from '../Components/DatePicker/Date';
import NavHeader from '../Components/Header/NavHeader';
import { ReactComponent as DateIcon } from '../Components/Header/svgs/date.svg';
import { CustomSelect } from '../Components/SelectOption/SelectOption';
import Spinner, { Spinnie } from '../Components/Spinner';
import { getFormatDate } from '../Functions/date';
import '../Styles/Sales/Sales.css';
import RepairListing from './RepairListing';

function SalesReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const [salesData, setSalesData] = useState(null);
  const [staffSalesDate, setStaffSalesData] = useState([]);
  const [selectedStaffId, setSelectedStaffId] = useState('');
  const [totalStaffSalesCount, setTotalStaffSalesCount] = useState(0);
  const [staffMembers, setStaffMembers] = useState([]);
  const [toDate, setToDate] = useState(dayjs(new Date()));
  const [fromDate, setFromDate] = useState(dayjs(new Date()));
  const [activeButton, setActiveButton] = useState('button1');
  const [paymentModeFilter, setPaymentModeFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [tableSpinner, setTableSpinner] = useState(true);
  const [page, setPage] = useState(1);

  const [showFromDate, setShowFromDate] = useState(false);
  const [showToDate, setShowToDate] = useState(false);

  useEffect(() => {
    fetchSalesData();
  }, [toDate, fromDate]);

  useEffect(() => {
    setTableSpinner(true);
    fetchStaffSales(selectedStaffId, 1);
  }, [paymentModeFilter, selectedStaffId, toDate, fromDate]);

  const loggedInUserShopId =
    JSON.parse(localStorage.getItem('user'))?._id ?? undefined;

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/staffMember?shop=${loggedInUserShopId}`
      )
      .then((response) => {
        const staffData = response.data.data.staffMembers;
        const staffOptions = staffData.map((staff) => ({
          label: staff.name,
          value: staff._id,
        }));
        setStaffMembers(staffOptions);
      })
      .catch((error) => {
        console.error('Error fetching staff members:', error);
      });
  }, []);

  // Fetch sales data for the selected staff member
  const handleSelect = (selectedValue) => {
    setSelectedStaffId(selectedValue);
  };

  const fetchStaffSales = async (staffId, page) => {
    let apiUrl = staffId
      ? `cart?staff=${staffId}&method=${paymentModeFilter}`
      : `cart?method=${paymentModeFilter}`;
    const startDate = fromDate;
    const endDate = toDate;

    if (startDate && endDate) {
      apiUrl += `&dateFrom=${startDate.format(
        'MM-DD-YYYY'
      )}&dateTo=${endDate.format('MM-DD-YYYY')}`;
    }

    setLoading(true);

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/${apiUrl}&pageNumber=${page}&records=${20}&shop=${loggedInUserShopId}`
      );
      const newData = response.data.data.items;

      setStaffSalesData((prevData) => {
        if (page === 1) {
          return [...newData];
        }
        return [...prevData, ...newData];
      });

      setTotalStaffSalesCount(response.data.data.count);
      setTableSpinner(false);
    } catch (error) {
      console.error('Error fetching sales data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableScroll = (event) => {
    const { target } = event;
    if (
      Math.floor(target.scrollHeight - target.scrollTop) ==
        target.clientHeight &&
      !loading
    ) {
      setPage((prevPage) => prevPage + 1);
      fetchStaffSales(selectedStaffId, page);
    }
  };

  const calculateTotalPrice = (productsList) => {
    const total = productsList.reduce((acc, price) => acc + price, 0);
    return total.toFixed(2); // Format to two decimal places
  };

  const calculateDiscountedPrice = (price, discountPercentage) => {
    // Ensure price and discountPercentage are valid numbers
    if (typeof price !== 'number' || typeof discountPercentage !== 'number') {
      return null; // Return null for invalid inputs
    }

    // Calculate the discounted price
    const discountAmount = (price * discountPercentage) / 100;
    const discountedPrice = price - discountAmount;

    return discountedPrice;
  };

  const fetchSalesData = async () => {
    const startDate = fromDate;
    const endDate = toDate;
    try {
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/cart/stat?shop=${loggedInUserShopId}`;

      if (startDate && endDate) {
        apiUrl += `&dateFrom=${startDate.format(
          'MM-DD-YYYY'
        )}&dateTo=${endDate.format('MM-DD-YYYY')}`;
      }
      const response = await axios.get(apiUrl);
      setSalesData(response.data.data);
    } catch (error) {
      console.error('Error while fetching sales data:', error);
    }
  };

  const formatDateToYYYYMMDD = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, 0);
    const day = date.getDate().toString().padStart(2, 0);

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  const calculateTotalDiscount = (
    price = 0,
    discount = 0,
    superDiscount = 0
  ) => {
    const discountedPrice =
      price - price * (1 - discount / 100) * (1 - superDiscount / 100);
    const totalDiscount = (discountedPrice / price) * 100;
    return totalDiscount;
  };
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleDateChange = (newDate, type) => {
    if (!isAuthenticated) return setShowAuthModal(true);
    if (type === 'fromDate') setFromDate(newDate);
    else setToDate(newDate);
  };

  const handleSuccessAuth = () => {
    setIsAuthenticated(true);
    setShowAuthModal(false);
  };

  if (!isAuthenticated && showAuthModal)
    return <Authenticate runAfterSuccess={handleSuccessAuth} />;

  return (
    <>
      <NavHeader
        navs='1'
        toDate={toDate}
        setToDate={(date) => handleDateChange(date, 'toDate')}
        fromDate={fromDate}
        setFromDate={(date) => handleDateChange(date, 'fromDate')}
        paymentModeFilter={paymentModeFilter}
        setPaymentModeFilter={setPaymentModeFilter}
        handleResetDate={() => {
          setFromDate(dayjs(new Date()));
          setToDate(dayjs(new Date()));
        }}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='sales-report-body'>
          <div
            className='sales-row'
            style={{
              justifyContent: 'flex-end',
              gap: '10px',
              marginBottom: '20px',
            }}
          >
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <p style={{ marginBlock: 0 }}>From</p>
                <Button
                  text={getFormatDate(fromDate)}
                  icon={<DateIcon />}
                  bgcolor='#F3F3F3'
                  color='#000'
                  onClick={() => {
                    if (!isAuthenticated) return setShowAuthModal(true);
                    setShowFromDate((st) => !st);
                    setShowToDate(false);
                  }}
                />
              </div>
              {showFromDate && (
                <DatePicker
                  date={fromDate}
                  setDate={setFromDate}
                  onClose={() => {
                    setShowFromDate((st) => !st);
                  }}
                />
              )}
            </div>
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <p style={{ marginBlock: 0 }}>To</p>
                <Button
                  text={getFormatDate(toDate)}
                  icon={<DateIcon />}
                  bgcolor='#F3F3F3'
                  color='#000'
                  onClick={() => {
                    if (!isAuthenticated) return setShowAuthModal(true);
                    setShowToDate((st) => !st);
                    setShowFromDate(false);
                  }}
                />
              </div>
              {showToDate && (
                <DatePicker
                  date={toDate}
                  setDate={setToDate}
                  onClose={() => {
                    setShowToDate((st) => !st);
                  }}
                />
              )}
            </div>
          </div>

          <div className='sales-row'>
            <div className='sales-left'>
              <table class='blueTable'>
                <thead>
                  <tr>
                    <th style={{ width: '10%' }}></th>
                    <th>Sale</th>
                    <th>Refund </th>
                    <th>Paidout</th>
                    {/* <th>Subtotal</th> */}
                  </tr>
                </thead>
                {salesData ? (
                  <tbody>
                    <tr>
                      <td className='head'>Cash</td>
                      <td>{salesData?.saleStat?.cash || 0}</td>
                      <td>{salesData?.refundStat?.cash || 0}</td>
                      <td>{salesData?.paidOutStat?.cash || 0}</td>
                      {/* <td>
                        {(+salesData.saleStat?.cash?.toFixed(2) || 0) +
                          (+salesData.refundStat?.cash?.toFixed(2) || 0) -
                          (+salesData.paidOutStat?.cash?.toFixed(2) || 0)}
                      </td> */}
                      {/* {calculateTotalPrice([
                          salesData.saleStat?.cash || 0,
                          salesData.refundStat?.cash || 0,
                          salesData.paidOutStat?.cash || 0,
                        ])} */}
                    </tr>
                    <tr>
                      <td className='head'>Credit</td>
                      <td>{salesData?.saleStat?.credit || 0}</td>
                      <td>{salesData?.refundStat?.credit || 0}</td>
                      <td>{salesData?.paidOutStat?.credit || 0}</td>
                      {/* <td>
                        {(+salesData.saleStat?.credit?.toFixed(2) || 0) +
                          (+salesData.refundStat?.credit?.toFixed(2) || 0) -
                          (+salesData.paidOutStat?.credit?.toFixed(2) || 0)}
                      </td> */}
                      {/* {calculateTotalPrice([
                          salesData.saleStat?.credit || 0,
                          salesData.refundStat?.credit || 0,
                          salesData.paidOutStat?.credit || 0,
                        ])} */}
                    </tr>
                    <tr>
                      <td className='head'>Subtotal</td>
                      <td>
                        {calculateTotalPrice([
                          salesData?.saleStat?.cash || 0,
                          salesData?.saleStat?.credit || 0,
                        ])}
                      </td>
                      <td>
                        {calculateTotalPrice([
                          salesData.refundStat?.cash || 0,
                          salesData.refundStat?.credit || 0,
                        ])}
                      </td>
                      <td>
                        {calculateTotalPrice([
                          salesData.paidOutStat?.cash || 0,
                          salesData.paidOutStat?.credit || 0,
                        ])}
                      </td>
                      {/* <td></td> */}
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className='head'>Cash</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className='head'>Credit</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className='head'>Subtotal</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                    </tr>
                  </tbody>
                )}
              </table>
              {/* <div className='select-user'>
                <CustomSelect
                  options={staffMembers}
                  onSelect={handleSelect}
                  name='Select Staff'
                />
              </div> */}
            </div>
            <div className='sales-right'>
              <div className='colin'>
                <div className='orange-box'>
                  <h3>Total Sales</h3>
                  <div className='bpx'>
                    <h2>
                      $
                      {(
                        calculateTotalPrice([
                          salesData?.saleStat?.cash || 0,
                          salesData?.saleStat?.credit || 0,
                        ]) -
                        calculateTotalPrice([
                          salesData?.refundStat?.cash || 0,
                          salesData?.refundStat?.credit || 0,
                        ])
                      ).toFixed(2)}
                    </h2>
                  </div>
                </div>
                <div className='blue-box'>
                  <h3>Sold Items</h3>
                  <div className='bpx'>
                    <h2>
                      {salesData?.saleStat?.count -
                        salesData?.refundStat?.count}
                    </h2>
                  </div>
                </div>
              </div>
              <div className='colin'>
                <div className='orange-box'>
                  <h3>Cash Sale</h3>
                  <div className='bpx'>
                    <h2>
                      $
                      {(+salesData.saleStat?.cash || 0) +
                        (+salesData.refundStat?.cash || 0) -
                        (+salesData.paidOutStat?.cash || 0)}
                    </h2>
                  </div>
                </div>
                <div className='blue-box'>
                  <h3>Card Sale</h3>
                  <div className='bpx'>
                    <h2>
                      $
                      {(+salesData?.saleStat?.credit || 0) +
                        (+salesData?.refundStat?.credit || 0) -
                        (+salesData?.paidOutStat?.credit || 0)}
                    </h2>
                  </div>
                </div>
              </div>
              {/* <div className='red-box'>
                <AiOutlineInfo className='i' />
                <p>
                  You can now print your whole sale report just by clicking on
                  print report.
                </p>
                <button>Print Report</button>
              </div> */}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBlock: '20px',
            }}
          >
            <div className='tabs-box-container'>
              <div
                className='buttons-tab-container'
                style={{
                  width: '310px',
                }}
              >
                <button
                  onClick={() => {
                    setTableSpinner(true);
                    handleButtonClick('button1');
                    setTimeout(() => {
                      setTableSpinner(false);
                    }, 2000);
                  }}
                  style={{
                    backgroundColor:
                      activeButton === 'button1' ? '#FF7C5C' : '#F5F5F5',
                    color: activeButton === 'button1' ? '#fff' : '#000',
                  }}
                >
                  Sales Report
                </button>
                <button
                  onClick={() => {
                    setTableSpinner(true);
                    handleButtonClick('button2');
                    setTimeout(() => {
                      setTableSpinner(false);
                    }, 2000);
                  }}
                  style={{
                    backgroundColor:
                      activeButton === 'button2' ? '#FF7C5C' : '#F5F5F5',
                    color: activeButton === 'button2' ? '#fff' : '#000',
                  }}
                >
                  Repair Listing
                </button>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
              }}
            >
              <div
                className='select-user'
                style={{
                  minWidth: '350px',
                }}
              >
                <CustomSelect
                  options={staffMembers}
                  onSelect={handleSelect}
                  name='Select Staff'
                  size='small'
                  color='grey'
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '3px',
                  width: 'fit-content',
                }}
              >
                <div
                  className='btn-floe'
                  style={{ display: 'flex', width: '33%', minWidth: '20px' }}
                >
                  <BuyerNavButton
                    text='All'
                    style={{
                      width: '100%',
                      flex: '1 1 0%',
                      display: 'flex',
                      alignItems: ' center',
                      justifyContent: 'center',
                    }}
                    bgcolor={paymentModeFilter === '' ? `#FF7C5C` : `#F3F3F3`}
                    color={paymentModeFilter === '' ? `#ffffff` : `#000`}
                    onClick={() => setPaymentModeFilter('')}
                  />
                </div>
                <div
                  className='btn-floe'
                  style={{ display: 'flex', width: '33%', minWidth: '20px' }}
                >
                  <BuyerNavButton
                    text='Cash'
                    style={{
                      width: '100%',
                      flex: '1 1 0%',
                      display: 'flex',
                      alignItems: ' center',
                      justifyContent: 'center',
                    }}
                    bgcolor={
                      paymentModeFilter === 'cash' ? `#FF7C5C` : `#F3F3F3`
                    }
                    color={paymentModeFilter === 'cash' ? `#ffffff` : `#000`}
                    onClick={() => setPaymentModeFilter('cash')}
                  />
                </div>
                <div
                  className='btn-floe'
                  style={{ display: 'flex', width: '33%', minWidth: '20px' }}
                >
                  <BuyerNavButton
                    text='Credit'
                    style={{
                      width: '100%',
                      flex: '1 1 0%',
                      display: 'flex',
                      alignItems: ' center',
                      justifyContent: 'center',
                    }}
                    bgcolor={
                      paymentModeFilter === 'credit' ? `#FF7C5C` : `#F3F3F3`
                    }
                    color={paymentModeFilter === 'credit' ? `#ffffff` : `#000`}
                    onClick={() => setPaymentModeFilter('credit')}
                  />
                </div>
              </div>
            </div>
          </div>
          {activeButton === 'button1' && (
            <>
              {tableSpinner ? (
                <>
                  <div className='sales-table'>
                    <table className='blueTable'>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Invoice</th>
                          <th>Stockid</th>
                          <th>Status</th>
                          <th>Price</th>
                          <th>Discount</th>
                          <th>Mode</th>
                          <th>Date</th>
                          <th>Sales Person</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <Spinnie />
                </>
              ) : (
                <>
                  {' '}
                  <div
                    className='sales-table'
                    style={{
                      maxHeight: 'unset',
                    }}
                    onScroll={handleTableScroll}
                  >
                    {staffSalesDate && staffSalesDate.length > 0 ? (
                      <>
                        <table className='blueTable'>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Invoice</th>
                              <th>Stockid</th>
                              <th>Status</th>
                              <th>Price</th>
                              <th>Discount</th>
                              <th>Mode</th>
                              <th>Date</th>
                              <th>Sales Person</th>
                              <th>Notes</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.from(new Set(staffSalesDate)).map(
                              (sale, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{sale.invoice || 'N/A'}</td>
                                  <td>{sale.itemsData.barCode || 'N/A'}</td>
                                  <td>{sale.items.reason || 'N/A'}</td>
                                  <td>
                                    {sale.items.isFaulty
                                      ? '0'
                                      : (
                                          calculateDiscountedPrice(
                                            sale.itemsData.price,
                                            calculateTotalDiscount(
                                              sale.items.price,
                                              sale.items.discount,
                                              sale.discount
                                            ) || 0
                                          ) * sale.items.numberOfItems
                                        )
                                          .toFixed(2)
                                          .replace(/\.00$/, '')}
                                  </td>
                                  <td>
                                    {sale.items.isFaulty
                                      ? 0
                                      : calculateTotalDiscount(
                                          sale.items.price,
                                          sale.items.discount,
                                          sale.discount
                                        ).toFixed(2)}
                                    %
                                  </td>
                                  <td>{sale.method || 'N/A'}</td>
                                  <td>
                                    {formatDateToYYYYMMDD(sale.createdAt) ||
                                      'N/A'}
                                  </td>
                                  <td>{sale?.saleMan?.userName || 'N/A'}</td>
                                  <td
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      maxWidth: '170px',
                                    }}
                                  >
                                    {sale?.items?.notes || '-'}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <table className='blueTable'>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Invoice</th>
                              <th>Stockid</th>
                              <th>Status</th>
                              <th>Price</th>
                              <th>Discount</th>
                              <th>Mode</th>
                              <th>Date</th>
                              <th>Sales Person</th>
                            </tr>
                          </thead>
                        </table>
                        <div className='no-data'>
                          <p>No data available</p>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
          {activeButton === 'button2' && (
            <div className='listing-report-table'>
              {' '}
              <RepairListing nav={false} fromDate={fromDate} toDate={toDate} />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default SalesReport;
