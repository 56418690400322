import React from 'react'
import '../Styles/Input.css'

function Input({ type, placeholder, value, icon,onChange }) {
  return (
   <div className='input-login-container'>
      <p className='input-icon'>{icon}</p>
     <input
       type={type}
       placeholder={placeholder}
       value={value}
       onChange={onChange}
       className='login-input'
     />
   </div> 
  );
}



export default Input 