import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../../Styles/RepairSheet/RepairListing.css';
import { Spinnie } from '../Spinner';

const RequestTable = ({ type }) => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!type) return;
    (async () => {
      setFetching(true);
      try {
        let shopId = JSON.parse(localStorage.getItem('user'))?._id;
        const resp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/unlocking?status=${type}&shop=${shopId}`
        );
        setData(resp.data.data);
      } catch (er) {
      } finally {
        setFetching(false);
      }
    })();
  }, [type]);

  return (
    <div className='repair-listing'>
      <div className='repair-listing-table'>
        {fetching ? (
          <>
            <div className='sales-table'>
              {' '}
              <table className='blueTable'>
                <thead>
                  <tr>
                    <th className='shrink'>Service</th>
                    <th>Date</th>
                    <th>Invoice</th>
                    <th>IMEI</th>
                    <th>Model</th>
                    <th>Network</th>
                    <th>Customer Name</th>
                    <th>Contact</th>
                    <th>Charges</th>
                    <th>Code</th>
                    <th>Status</th>
                  </tr>
                </thead>
              </table>
            </div>
            <Spinnie />
          </>
        ) : data?.count > 0 ? (
          <div
            style={{
              width: ' 100%',
              overflowY: 'scroll',
              maxHeight: '300px',
              marginBottom: '50px',
            }}
            // onScroll={handleTableScroll}
          >
            <table className='blueTable'>
              <thead>
                <tr>
                  <th>Invoice</th>
                  <th>Service</th>
                  <th>IMEI</th>
                  <th>Model</th>
                  <th>Network</th>
                  <th>Customer Name</th>
                  <th>Customer Email</th>
                  <th>Contact</th>
                  <th>Charges</th>
                  <th>Date</th>
                  <th>Code</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data?.list
                  // .filter((item) => item.sentToLab === false)
                  ?.map((repair, index) => (
                    <tr key={repair._id}>
                      <td className='shrink'>{repair.invoice}</td>
                      <td className='shrink'>{repair?.service?.service}</td>
                      <td className='shrink'>{repair.imei}</td>
                      <td className='shrink'>{repair.model}</td>
                      <td className='shrink'>{repair.network}</td>
                      <td className='shrink'>{repair.customerName}</td>
                      <td className='shrink'>{repair.customerEmail}</td>
                      <td className='shrink'>{repair.customerContact}</td>
                      <td className='shrink'>£{repair.charges}</td>
                      <td className='shrink'>
                        {new Date(repair.createdAt).toLocaleDateString()}
                      </td>
                      <td className='shrink'>{repair.comment}</td>
                      <td
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {repair.status}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <>
            <table className='blueTable'>
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Date</th>
                  <th>Invoice</th>
                  <th>IMEI</th>
                  <th>Model</th>
                  <th>Network</th>
                  <th>Customer Name</th>
                  <th>Contact</th>
                  <th>Charges</th>
                  <th>Status</th>
                </tr>
              </thead>
            </table>
            <div
              style={{
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: 500,
                marginBlock: '30px',
              }}
            >
              <p>No data available</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RequestTable;
