import React, { useState } from 'react';
import PrintJS from 'print-js';

function PrinterComponent() {
  const [responseMessage, setResponseMessage] = useState('');

  const sendPrintCommand = async () => {
    try {
      // const response = await fetch('http://localhost:7000/print', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     commands: [
      //       { method: 'initialize' },
      //       { method: 'text', args: ['Hello, printer!'] },
      //       { method: 'newline' },
      //       { method: 'encode' }
      //     ]
      //   })
      // });

      PrintJS({
        printable: 'printable-area',
        type: 'html',
        targetStyles: ['*'] // Apply styles to the entire document
      });

      // const data = await response.json();
      // if (response.ok) {
      //   setResponseMessage(data.message); // Handle success message
      // } else {
      //   setResponseMessage('Error: ' + data.error); // Handle error message
      // }
    } catch (error) {
      setResponseMessage('Error: ' + error.message); // Handle fetch error
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '30% 0px',
        flexDirection: 'co'
      }}
    >
      <button onClick={sendPrintCommand}>Print</button>
      <div id='printable-area'>
        {/* Your receipt content here */}
        <h2>{'here title'}</h2>
        <p>{'here description'}</p>
      </div>
      <p>{responseMessage}</p>
    </div>
  );
}

export default PrinterComponent;
