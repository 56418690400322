import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { InputBox } from '../../Pages/InputBox';
import '../../Styles/RepairSheet/RepairSheet.css';
import { BuyerNavButton } from '../Buttons';
import Select from '../SelectOption/Select';
import Spinner from '../Spinner';

const getFormatDate = (currentDate) => {
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');

  // Construct the formatted date
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const AddRequest = () => {
  const { user } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(null);
  const [serviceList, setServiceList] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [customSelectOpen2, setCustomSelectOpen2] = useState(false);
  const [formData, setFormData] = useState({
    imei: '',
    model: '',
    network: '',
    customerName: '',
    customerEmail: '',
    customerContact: '',
    charges: 0,
    comment: '',
    invoice: Math.floor(100000 + Math.random() * 900000).toString(),
    date: getFormatDate(new Date()),
  });

  const resetState = () => {
    setSelectedService(null);
    setFormData({
      imei: '',
      model: '',
      network: '',
      customerName: '',
      customerEmail: '',
      customerContact: '',
      charges: '',
      comment: '',
      invoice: Math.floor(100000 + Math.random() * 900000).toString(),
      date: getFormatDate(new Date()),
    });
  };

  const getServCharges = (service) => {
    const start = service.indexOf('£') + 1;
    const end = service.indexOf('-', start);
    return service.substring(start, end).trim();
  };

  useEffect(() => {
    (async () => {
      try {
        setFetching(true);
        const resp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/unlockService`
        );
        const serviceList = resp?.data?.data || [];
        if (serviceList.length)
          setServiceList(
            serviceList?.map?.((el) => {
              const charges = getServCharges(el.service);
              return {
                label: el.service,
                value: el._id,
                charges,
              };
            })
          );
      } catch (er) {
      } finally {
        setFetching(false);
      }
    })();
  }, []);

  const handleInputChange = (key, value) =>
    setFormData({ ...formData, [key]: value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (hasEmptyField(formData))
      setError('Please fill all the required fields marked with *');
    else {
      try {
        setLoading(true);
        let shopId = JSON.parse(localStorage.getItem('user'))?._id;
        let { date, invoice, ...newFormData } = formData;
        if (!shopId) return;
        await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/unlocking`, {
          ...newFormData,
          service: selectedService,
          user: user._id,
          shop: shopId,
        });
        toast.success('Request submmitted successfully');
        resetState();
      } catch (er) {
      } finally {
        setLoading(false);
      }
    }
  };

  const onServiceSelect = (val) => {
    setSelectedService(val);
    const getServCharges = serviceList.filter((el) => el.value === val)[0]
      .charges;
    setFormData((st) => ({ ...st, charges: getServCharges }));
  };

  return (
    <>
      {fetching ? (
        <Spinner />
      ) : (
        <div
          style={{
            background: '#F5F5F5',
            width: '100%',
            padding: '20px 30px 30px',
            borderRadius: 10,
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: '5px',
                '& .input-box': {
                  width: '100% !important',
                },
              }}
            >
              <Grid item xs={12}>
                <div className='unl-content'>
                  <p style={{ marginTop: 0, marginBottom: '7px' }}>*Service</p>
                  <Select
                    options={serviceList}
                    isOpen={customSelectOpen2}
                    setIsOpen={setCustomSelectOpen2}
                    onClick={() => {}}
                    name='shop'
                    placeholder='Select Service'
                    icon={true}
                    onSelect={onServiceSelect}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputBox
                  text='*Date'
                  placeholder='Enter Date'
                  type='text'
                  value={formData.date}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputBox
                  text='*Invoice'
                  placeholder='Enter Invoice No'
                  type='text'
                  value={formData.invoice}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputBox
                  text='*IMEI#'
                  placeholder='Enter IMEI#'
                  type='text'
                  value={formData.imei}
                  onChange={(value) => handleInputChange('imei', value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputBox
                  text='*Model'
                  placeholder='Enter Model'
                  type='text'
                  value={formData.model}
                  onChange={(value) => handleInputChange('model', value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputBox
                  text='*Network'
                  placeholder='Enter Network'
                  type='text'
                  value={formData.network}
                  onChange={(value) => handleInputChange('network', value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputBox
                  text='*Customer Name'
                  placeholder='Enter Customer Name'
                  type='text'
                  value={formData.customerName}
                  onChange={(value) => handleInputChange('customerName', value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputBox
                  text='*Customer Email'
                  placeholder='Enter Customer Email'
                  type='email'
                  value={formData.customerEmail}
                  onChange={(value) =>
                    handleInputChange('customerEmail', value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputBox
                  text='*Customer Contact'
                  placeholder='Enter Customer Contact'
                  type='text'
                  value={formData.customerContact}
                  onChange={(value) =>
                    handleInputChange('customerContact', value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputBox
                  text='*Charges (£)'
                  placeholder='Enter Charges'
                  value={formData.charges}
                  disabled={true}
                  // onChange={(value) => handleInputChange('charges', value)}
                />
              </Grid>
              <Grid item xs={12}>
                <div className='input-box'>
                  <label>Notes</label>
                  <textarea
                    value={formData.comment}
                    placeholder='Please dont use slashes or quotes while entering notes'
                    onChange={(e) =>
                      handleInputChange('comment', e.target.value)
                    }
                    rows={5}
                    style={{
                      resize: 'none',
                    }}
                  />
                </div>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <p style={{ color: 'red' }}>{error}</p>
                </Grid>
              )}
              <Grid item xs={12} sx={{ marginTop: 3 }}>
                <BuyerNavButton
                  text={loading ? 'Saving...' : 'Save'}
                  bgcolor='#ff7c5c'
                  color='white'
                  type='submit'
                  disabled={loading}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </>
  );
};

const hasEmptyField = (obj) => {
  return Object.values(obj).some(
    (value) => value === '' || value === null || value === undefined
  );
};

export default AddRequest;
