import React from 'react'

function Box({time,name,icon}) {
  return (
    <div className='big-box'>
        <div className='upper-box'>
          <span>{icon}</span>
          <h3>{name}</h3>
        </div>

        {/* <p>{time}</p> */}
    </div>
  )
}

export default Box