import axios from 'axios';
import PrintJS from 'print-js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Printer, render, Text } from 'react-thermal-printer';
import { ReactComponent as CloseIcon } from '../Components/Header/svgs/close.svg';
import '../Styles/Modal.css'; // Import your CSS for modal styling
import Input from './Input';
import Print from './Print';
import { CustomSelectTwo } from './SelectOption/SelectOption';
import { MButton } from './Buttons';
import { ReactComponent as PaidoutIcon } from "../Pages/Svg/paidout.svg";

function CheckoutModal({
  isOpen,
  onClose,
  checkoutCart,
  showReceipt,
  searchedProducts,
  totalDiscount,
  discountedTotalAmount,
  calculateTotalPrice,
  invoice,
  paymentMode,
  userName,
  netDiscount,
}) {
  const [vatAmount, setVatAmount] = useState(0);
  const netAmount = discountedTotalAmount
    ? discountedTotalAmount
    : calculateTotalPrice(searchedProducts);

  console.log('discountedTotalAmount', discountedTotalAmount);
  console.log('netAmount', netAmount);
  console.log('totalDiscount', totalDiscount);

  useEffect(() => {
    if (netAmount) {
      const vatPercentage = 20;
      setVatAmount((netAmount * vatPercentage) / 100);
    }
  }, [netAmount]);

  const currentDate = new Date();

  // Format date as DD/MM/YYYY
  const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1
    }/${currentDate.getFullYear()}`;

  // Format time as HH:mm:ss
  const formattedTime = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

  if (!isOpen) return null;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  // Calculate VAT amount (fixed at 20%)

  const getTotalAmountWithVat = (netAmount, vatAmount) => {
    const total = parseFloat(netAmount) + parseFloat(vatAmount);
    return total.toFixed(2);
  };

  const handlePrint = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/printer/`,
        {
          invoice, formattedDate, formattedTime, searchedProducts,
          netAmount, vatAmount, userName
        }
      )


    } catch (err) {

    } finally {

    }
  }

  return (
    <div className='modal-overlay'>
      <div className='modal'>
        <div className='Upper-modal-row'>
          {showReceipt == false ? (
            <h2>Choose Payment Method</h2>
          ) : (
            <h2>Reciept</h2>
          )}
          <CloseIcon onClick={onClose} />
        </div>
        {showReceipt == false ? (
          <>
            {' '}
            <div className='row-modal-eir'></div>
          </>
        ) : (
          <>
            <div className='reciept-container'>
              <div className='reciept' id='reciept-container'>
                <h1>Freedom Phones</h1>
                <div className='reciept-row'>
                  <p>9 stall Street</p>
                  <p>#Invoice : {invoice}</p>
                </div>

                <div className='reciept-row'>
                  <p>Somerset</p>
                  <p>{formattedDate}</p>
                </div>
                <div className='reciept-row'>
                  <p>BA16 0EN</p>
                  <p>{formattedTime}</p>
                  {/* <p style={{ color: 'white' }}>#</p> */}
                </div>
                <div className='reow-reciept'>
                  <h3 className='quantity'>Quantity</h3>
                  <h3 className='product'>Product</h3>
                  <h3 className='price'>Price</h3>
                </div>
                {searchedProducts.map((item) => (
                  <div className='reow-reciept' key={item.uuid}>
                    <p className='quantity'>{item.numberOfItems}</p>
                    <p className='product'>{item.partName}</p>
                    <p className='price'>
                      {!isNaN(item.numberOfItems) ? (
                        (() => {
                          const numberOfItems = item.numberOfItems;
                          const price = item.price;
                          const totalDiscount = +item.discount + +netDiscount;

                          const discountedPrice = item.discountedPrice;
                          console.log('discountedPrice', discountedPrice);
                          // const totalPrice =
                          //   discountedPrice !== undefined
                          //     ? discountedPrice * numberOfItems
                          //     : price * numberOfItems;
                          const totalPrice =
                            totalDiscount > 0
                              ? price -
                              price * numberOfItems * (totalDiscount / 100)
                              : price * numberOfItems;

                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {/* <p>£{item.price}</p> */}
                              <p>£{totalPrice.toFixed(2)}</p>
                            </div>
                          );
                        })()
                      ) : (
                        <div></div>
                      )}
                    </p>
                  </div>
                ))}
                <div
                  className='reciept-row'
                  style={{ borderTop: '1px dotted black' }}
                >
                  <p>Sub-Total</p>
                  <p style={{ fontWeight: 600 }}>
                    £{(netAmount - vatAmount).toFixed(2)}
                  </p>
                  {/* <p>Discount</p>
                  <p>{totalDiscount}</p> */}
                </div>
                <div className='reciept-row'>
                  <p>VAT Amount</p>
                  <p>£{netAmount > 0 ? vatAmount.toFixed(2) : 0}</p>
                </div>
                {/* <div
                  className='reciept-row'
                  style={{ borderBottom: '1px dotted black' }}
                >
                  <p>Discount</p>
                  <p>{totalDiscount}</p>
                </div> */}
                <div className='reciept-row'>
                  <p>Total </p>
                  <p style={{ fontWeight: 600 }}> £{netAmount}</p>
                </div>
                <div className='reciept-row'>
                  <p>Cash/Card</p>
                </div>
                <div className='reciept-row'>
                  <p>Due</p>
                  <p>
                    {' '}
                    {calculateTotalPrice(searchedProducts) > 0
                      ? 0
                      : -1 * calculateTotalPrice(searchedProducts)}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <p style={{ margin: '5px auto' }}>{userName}</p>
                  <p style={{ margin: '5px auto' }}>
                    Thanks for shopping with us
                  </p>
                  <p style={{ margin: '5px auto' }}>www.freedomphones.co.uk</p>
                </div>
              </div>
            </div>
            <MButton
              text="Print"
              icon={<PaidoutIcon />}
              bgcolor="#FF7C5C"
              color="#fff"
              onClick={handlePrint}
            />
            {/* <Print
              printReciept={() => {
                PrintJS({
                  printable: 'reciept-container',
                  type: 'html',
                  targetStyles: ['*'], // Apply styles to the entire document
                });
              }}
            /> */}
          </>
        )}
      </div>
    </div>
  );
}

export default CheckoutModal;
