import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ReactComponent as CloseIcon } from '../Components/Header/svgs/close.svg';
import { StaffSelect } from '../Components/SelectOption/StaffSelect';
import '../Styles/Modal.css'; // Import your CSS for modal styling

function Modal({ isOpen, onClose, children }) {
  const user = useSelector((state) => state.user.user);
  const [staffMembers, setStaffMembers] = useState([]);
  const [staffId, setStaffId] = useState(null);
  const [formData, setFormData] = useState({
    userName: '',
    amount: '',
    method: 'credit',
    reason: '',
  });

  useEffect(() => {
    // Make an API call to fetch staff members
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/staffMember?shop=${loggedInUserShopId}`
      )
      .then((response) => {
        const staffData = response.data.data.staffMembers;
        const staffOptions = staffData.map((staff) => ({
          label: staff.name,
          value: staff._id,
        }));
        setStaffMembers(staffOptions);
      })
      .catch((error) => {
        console.error('Error fetching staff members:', error);
      });
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const loggedInUserShopId =
    JSON.parse(localStorage.getItem('user'))?._id ?? undefined;
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Prepare the data in the required format
    const data = {
      user: staffId,
      shop: loggedInUserShopId,
      amount: parseFloat(formData.amount),
      method: formData.method,
      reason: formData.reason,
    };

    try {
      // Make a POST request to the specified endpoint with the formatted data
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/paidOut?shop=${loggedInUserShopId}`,
        data
      );
      toast.success('Paidout successful! Thank you for your purchase.');

      onClose();
      setFormData({
        userName: '',
        amount: '',
        method: '',
        reason: '',
      });
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error submitting data:', error);
    }
  };

  useEffect(() => {
    const fetchUsername = async (userId) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/staffMember/${userId}?shop=${loggedInUserShopId}`
        );
        setFormData((prevData) => ({
          ...prevData,
          userName: response.data.data.userName,
        }));
      } catch (error) {
        console.error(
          `Error while fetching username for user ID ${userId}:`,
          error
        );
      }
    };

    if (staffId) {
      fetchUsername(staffId);
    }
  }, [staffId, isOpen]);

  if (!isOpen) return null;

  return (
    <div className='modal-overlay'>
      <div className='modal' style={{ maxWidth: '35%' }}>
        <div className='Upper-modal-row'>
          <h2>Paidout Details</h2>
          <CloseIcon onClick={onClose} />
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className='row'>
            <div
              className='input-boxer'
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyItems: 'center',
                paddingBottom: '20px',
              }}
            >
              <StaffSelect
                options={staffMembers}
                onSelect={setStaffId}
                name='Select Staff'
                onClose={() => null}
              />
            </div>
            <div
              className='input-boxer'
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyItems: 'center',
                paddingBottom: '20px',
              }}
            >
              <input
                type='text'
                name='amount'
                placeholder='Enter amount'
                value={formData.amount}
                onChange={handleFormChange}
              />
              <div style={{ marginLeft: '-20px' }}>£</div>
            </div>
          </div>
          <div className='columner'>
            <h4>Payment Method</h4>
            <div className='rows'>
              <label className='checkbox-container'>
                <label className='rounded-checkbox'>
                  <input
                    type='radio'
                    name='method'
                    value='credit'
                    checked={formData.method === 'credit'}
                    onChange={handleFormChange}
                  />{' '}
                  <span className='checkmark'></span>
                </label>
                Via Credit{' '}
              </label>

              <label className='checkbox-container'>
                <label className='rounded-checkbox'>
                  <input
                    type='radio'
                    name='method'
                    value='cash'
                    checked={formData.method === 'cash'}
                    onChange={handleFormChange}
                  />{' '}
                  <span className='checkmark'></span>
                </label>
                Via Cash{' '}
              </label>
            </div>
            <textarea
              name='reason'
              placeholder='Enter a note'
              value={formData.reason}
              onChange={handleFormChange}
            />
            <button type='submit' className='modal-button'>
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Modal;
