import { Box, Stack } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useScanDetection from 'use-scan-detection';
import { v4 as uuidv4 } from 'uuid';
import { MButton } from '../Components/Buttons';
import CheckoutModal from '../Components/CheckOutModal';
import Header from '../Components/Header/Header';
import ModalSelection from '../Components/Header/ModalSelection';
import {
  InputSearchBar,
  InputSelectionCard,
} from '../Components/InputSelectionCard/InputSelectionCard';
import Modal from '../Components/Modal';
import RepairCheckoutModal from '../Components/RepairCheckoutModal';
import Spinner from '../Components/Spinner';
import '../Styles/Home/Home.css';
import { ReactComponent as ClosedIcon } from './Svg/closed.svg';
import { ReactComponent as PaidoutIcon } from './Svg/paidout.svg';

function HomePage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpens, setIsModalOpens] = useState(false);
  const [repairModalOpens, setRepairModalOpens] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [repairProducts, setRepairProducts] = useState([]);

  const [searchedProducts, setSearchedProducts] = useState([]);

  const [searchInput, setSearchInput] = useState(''); // Input for barcode
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedStaffId, setSelectedStaffId] = useState('');
  const [discount, setDiscount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [discountedTotalAmount, setDiscountedTotalAmount] = useState(0);
  const [showReceipt, setShowReceipt] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(0);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const [searchedItemStock, setSearchedItemStock] = useState(0);
  const [netDiscount, setNetDiscount] = useState(0);
  const [repairSheetReceipt, setRepairSheetReceipt] = useState({
    invoice: 0,
    charges: 0,
    deposit: 0,
    finalDeposit: 0,
    total: 0,
  });
  const [quantity, setQuantity] = useState(1);
  const [paymentMode, setPaymentMode] = useState('');
  const refundOptions = [
    { value: 'refund', label: 'Refund' },
    { value: 'faulty', label: 'Faulty' },
    { value: 'exchange', label: 'Exchange' },
    { value: 'sameReplace', label: 'Same Replace' },
  ];

  const [notes, setNotes] = useState('');

  const handleRefresh = () => {
    setSearchedProducts([]);
    setDiscountedTotalAmount(0);
    setTotalDiscount(0);
    setSelectedAction('');
    setSelectedOption('');
    setSearchInput('');
  };

  const loggedInUserShopId =
    JSON.parse(localStorage.getItem('user'))?._id ?? undefined;

  const completeRepairCheckout = async (repairId) => {
    try {
      const paramsBody = {
        status: 'fixed',
      };

      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/repair/repair/${repairId}?shop=${loggedInUserShopId}`,
        paramsBody
      );
      setShowReceipt(true);
      toast.success('Repair checkout completed successfully!');
    } catch (error) {
      toast.error('Error completing repair checkout');
    }
  };

  const checkoutCart = async (paymentMode, staff = selectedStaffId) => {
    if (repairProducts && repairProducts.length > 0) {
      if (repairProducts[0].isInitialDeposit) {
        const repairId = repairProducts[0].id; // Assuming repairProducts is an array

        const paramsBody = {
          repairId: repairId,
          method: paymentMode,
        };
        const finalDepositResponse = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/api/v1/repair/initialDeposit?shop=${loggedInUserShopId}`,
          paramsBody
        );

        setRepairSheetReceipt({
          ...repairSheetReceipt,
          total: discountedTotalAmount,
          invoice: finalDepositResponse.data.data.invoice,
          charges: finalDepositResponse.data.data.charges,
          deposit: finalDepositResponse.data.data.deposit,
          isInitialDeposit: true,
          isFinalDeposit: finalDepositResponse.data.data.isFinalDeposit,
          finalDeposit: finalDepositResponse.data.data.finalDeposit,
        });

        setShowReceipt(true);
        setRepairModalOpens(true);
      } else {
        const repairId = repairProducts[0].id; // Assuming repairProducts is an array
        let finalDeposit = 0;

        if (!repairProducts[0].finalDeposit) {
          finalDeposit = repairProducts[0].charges - repairProducts[0].deposit;
        } else {
          finalDeposit = 0;
        }
        const paramsBody = {
          repairId: repairId,
          finalDeposit: finalDeposit,
          method: paymentMode,
        };
        const finalDepositResponse = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/api/v1/repair/finalDeposit?shop=${loggedInUserShopId}`,
          paramsBody
        );

        setRepairSheetReceipt({
          ...repairSheetReceipt,
          total: discountedTotalAmount,
          invoice: finalDepositResponse.data.data.invoice,
          charges: finalDepositResponse.data.data.charges,
          deposit: finalDepositResponse.data.data.deposit,
          isFinalDeposit: finalDepositResponse.data.data.isFinalDeposit,
          isInitialDeposit: false,
          finalDeposit: finalDepositResponse.data.data.finalDeposit,
        });
        if (finalDeposit == 0) {
          await completeRepairCheckout(repairId);
        } else {
          setShowReceipt(true);
        }
        setRepairModalOpens(true);
      }
    } else {
      const tillProducts = searchedProducts.map((product) => {
        return {
          item: product._id,
          numberOfItems: product.numberOfItems || 0,
          vat: 0,
          discount: product.discount,
          type: 'product',
          reason: product.reason,
          status: product.status,
          action: product.action,
          isFaulty: product.isFaulty,
        };
      });

      const checkoutBody = {
        total: discountedTotalAmount,
        method: paymentMode,
        discount: totalDiscount ?? 0,
        items: tillProducts,
        saleMan: staff,
        shop: loggedInUserShopId,
        ...(!notes && notes),
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/cart?shop=${loggedInUserShopId}`,
          checkoutBody
        );
        setIsModalOpens(true);
        toast.success('Checkout successful! Thank you for your purchase.');
        setInvoiceNumber(response.data.data.invoice);
        setShowReceipt(true);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(`Error: ${error.response.data.message}`);
        } else {
          toast.error('Error while creating repair sheet!');
        }
      }
    }
  };

  const handlePaymentMode = (mode, staff = selectedStaffId) => {
    if (searchedProducts.length === 0 && repairProducts.length === 0) {
      toast.warning('Please add some items to the cart before proceeding.');
    } else if (staff === '') {
      toast.error(
        'Please select a salesperson before proceeding with the checkout.'
      );
    } else {
      setPaymentMode(mode);
      checkoutCart(mode, staff);
    }
  };

  // Function to calculate the total price from searchedProducts
  const calculateTotalPrice = (productsList) => {
    const totalPrice = productsList.reduce((total, product) => {
      let productTotal =
        (product.price || product.balance || 0) * (product.numberOfItems || 1);
      let discount = 0;
      if (product.discount > 0)
        discount = (product.discount / 100) * productTotal;
      const itemTotal = productTotal - discount;
      return total + itemTotal;
    }, 0);
    return totalPrice.toFixed(2);
  };

  const fetchItemByBarcode = async (barcode, type) => {
    try {
      if (100000 <= barcode && barcode < 200000) {
        if (repairProducts && repairProducts.length > 0) {
          toast.error('Please complete checkout for Repair Product first!');
        } else {
          // Make API call to the repair API
          const repairResponse = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/v1/repair/barcode/${barcode}?shop=${loggedInUserShopId}`
          );

          const repairProductData = repairResponse.data.data;
          if (
            repairProductData.isInitialDeposit == false &&
            repairProductData.isFinalDeposit == false
          ) {
            toast.info('This product payment already made!');
            return;
          }
          if (repairProductData?.status == 'fixed') {
            toast.info('This Product is already been fixed!');
            return;
          } else {
            if (repairProductData) {
              const modifiedProduct = {
                id: repairProductData._id,
                uuid: uuidv4(),
                barCode: repairProductData.barCode,
                partName: repairProductData.faults,
                price: repairProductData.isInitialDeposit
                  ? repairProductData.deposit
                  : repairProductData.balance,
                type: 'Repair',
                reason: 'Repair',
                action: null,
                deposit: repairProductData.deposit,
                balance: repairProductData.balance,
                isInitialDeposit: repairProductData.isInitialDeposit,
                charges: repairProductData.charges,
                finalDeposit: repairProductData.finalDeposit,
              };
              // Handle the repair product data as needed
              setRepairProducts([...repairProducts, modifiedProduct]);
            } else {
              // If the product is not found or the quantity is 0, display a toast message
              toast.error('No product found with the entered barcode.');
            }
          }
        }
      } else {
        if (repairProducts && repairProducts.length > 0) {
          toast.error('Please complete checkout for Repair Product first!');
        } else {
          // Make API call to the stock API
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/v1/stock/byBarCode/${barcode}?shop=${loggedInUserShopId}`
          );
          const productData = response.data.data;

          const isRefundOrFaulty =
            selectedOption === 'refund' || selectedOption === 'faulty';
          const priceModifier = isRefundOrFaulty ? -1 : 1;

          const price =
            // selectedOption === "faulty" ? 0 : priceModifier * productData.price;
            selectedOption === 'faulty' ? 0 : priceModifier * productData.price;

          if (type === 'search') {
            const existingProduct = searchedProducts.find(
              (product) =>
                product.barCode === barcode && selectedOption === product.reason
            );
            if (existingProduct) {
              // If the product is already in the cart, increase the quantity
              setSearchedProducts((prevProducts) => {
                const updatedProducts = prevProducts.map((prevProduct) => {
                  if (prevProduct.uuid === existingProduct.uuid) {
                    return {
                      ...prevProduct,
                      numberOfItems: prevProduct.numberOfItems + 1,
                    };
                  }
                  return prevProduct;
                });
                return updatedProducts;
              });
              return existingProduct;
            }

            // Check if the product is found and the quantity is greater than 0
            if (productData && productData.stock[0].itemsInStock > 0) {
              setSearchedItemStock(productData.stock[0].itemsInStock);
              const modifiedProduct = {
                _id: productData._id,
                uuid: uuidv4(), // Add a unique identifier
                numberOfItems: 1,
                barCode: productData.barCode,
                partName: productData.partName,
                price: price,
                vat: 0,
                discount: productData.discount,
                type: 'Product',
                reason: selectedOption === '' ? 'Sale' : selectedOption,
                status:
                  selectedOption === 'refund' || selectedOption === 'faulty'
                    ? 'refund'
                    : 'sale',
                action: selectedAction === '' ? null : selectedAction,
                isFaulty: selectedOption === 'faulty' ? true : false,
              };

              setSearchedProducts([...searchedProducts, modifiedProduct]);
              return modifiedProduct;
            } else {
              // If the product is not found or the quantity is 0, display a toast message
              toast.error(
                productData
                  ? 'Item is out of stock.'
                  : 'No product found with the entered barcode.'
              );
            }
          } else {
            return productData;
          }
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Display a toast message for other errors
      toast.error(
        'No product found with the entered barcode. OR Item is Out of Stock!'
      );
      return null;
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      try {
        await fetchItemByBarcode(searchInput, 'search');
        setSearchInput('');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const removeProduct = (productsList, setProductsList, id, index) => {
    const indexToRemove = index;

    if (indexToRemove !== -1) {
      // Remove the product at the found index
      const updatedProducts = [
        ...productsList.slice(0, indexToRemove),
        ...productsList.slice(indexToRemove + 1),
      ];

      setProductsList(updatedProducts);

      if (updatedProducts.length === 0) {
        // If the updated products list is empty, reset the total discount
        setTotalDiscount(null);
        setDiscountedTotalAmount(calculateTotalPrice([]));
      } else {
        // Recalculate total discount and discounted total amount
        const totalAmount = calculateTotalPrice(updatedProducts);
        const updatedDiscountedTotalAmount = totalDiscount
          ? totalAmount - totalAmount * (totalDiscount / 100)
          : totalAmount;

        // Round the updated discounted total amount to two decimal places
        const roundedUpdatedDiscountedTotalAmount =
          Math.round(updatedDiscountedTotalAmount * 100) / 100;

        setDiscountedTotalAmount(roundedUpdatedDiscountedTotalAmount);
      }
    }
  };

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeModals = () => {
    setIsModalOpens(false);
    setShowReceipt(false);
    if (showReceipt) {
      setSearchedProducts([]);
      setDiscountedTotalAmount(0);
      setTotalDiscount(0);
      setSelectedAction('');
      setSelectedOption('');
      setSearchInput('');
    }
  };

  const closeRepairModal = () => {
    setRepairModalOpens(false);
    setShowReceipt(false);
    if (showReceipt) {
      setRepairProducts([]);
      setSearchInput('');
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const calculateDiscountedPrice = (price, discount) => {
    const discountedPrice = price - price * (discount / 100);
    // Round the discounted price to two decimal places
    return Math.round(discountedPrice * 100) / 100;
  };
  const [barcode, setBarcode] = useState('');
  useScanDetection({
    onDetected: (scannedCode) => {
      setBarcode(scannedCode);
      fetchItemByBarcode(scannedCode, 'search');
    },
    timeBeforeScanTest: 200, // wait for the next character for upto 200ms
    avgTimeByChar: 40, // it's not a barcode if a character takes longer than 100ms
    minLength: 3, // wait for at least 6 characters
    suffixKeyCodes: [13], // terminate on enter. 13 is enter
  });

  const [userNames, setUserNames] = useState('');
  const [showSelectStaffModal, setShowSelectStaffModal] = useState(false);
  const [netValue, setNetValue] = useState(0);
  const calculateDiscountedTotal = (totalAmount, enteredTotalDiscount) => {
    if (enteredTotalDiscount) {
      const discountedTotalAmount =
        totalAmount - totalAmount * (enteredTotalDiscount / 100);

      return Math.round(discountedTotalAmount * 100) / 100;
    } else {
      return totalAmount;
    }
  };
  useEffect(() => {
    const totalAmount = calculateTotalPrice(searchedProducts);
    const updatedDiscountedTotal = calculateDiscountedTotal(
      totalAmount,
      totalDiscount
    );
    setDiscountedTotalAmount(updatedDiscountedTotal);

    // Calculate net value here based on repairProducts or discountedTotalAmount
    const net =
      repairProducts && repairProducts.length > 0
        ? calculateTotalPrice(repairProducts)
        : updatedDiscountedTotal || totalAmount;

    // calc mean discount
    let noOfDisc = 0;
    const meanDiscount =
      searchedProducts.reduce((total, product) => {
        if (product.discount > 0) noOfDisc += 1;
        return total + (product.discount || 0);
      }, 0) + totalDiscount;
    noOfDisc += totalDiscount > 0 ? 1 : 0;
    setNetDiscount((meanDiscount / noOfDisc).toFixed(0));
    setNetValue(net);
  }, [searchedProducts, repairProducts, totalDiscount]);

  return (
    <>
      <Header
        selectedStaffId={selectedStaffId}
        setSelectedStaffId={setSelectedStaffId}
        handleRefresh={handleRefresh}
        setUserNames={setUserNames}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='homepage'>
          <div className='body-layout'>
            <div className='left-container'>
              <InputSelectionCard
                heading='Select Option'
                options={refundOptions}
                selectedOption={selectedOption}
                onOptionChange={(e) => setSelectedOption(e.target.value)}
                onOptionClick={(e) =>
                  e.target.value == selectedOption
                    ? setSelectedOption('')
                    : setSelectedOption(e.target.value)
                }
              />

              <InputSearchBar
                heading='Products'
                placeholder='Enter Barcode then press Enter...'
                value={searchInput}
                onKeyDown={handleKeyPress} // Listen for Enter key press
                onChangeHandler={handleSearchInputChange}
              />
              <div className='ber'>
                <div className='table-container'>
                  {searchedProducts.length > 0 || repairProducts.length > 0 ? (
                    <table class='blueTable'>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Item ID</th>
                          <th>Price</th>
                          <th></th>
                        </tr>
                      </thead>
                      {repairProducts && repairProducts.length > 0 ? (
                        <tbody>
                          {repairProducts.map((product, index) => (
                            <tr key={product._id}>
                              <td>{product.partName}</td>
                              <td>{product.barCode}</td>
                              <td>{product.price}</td>

                              <td style={{ cursor: 'pointer' }}>
                                <ClosedIcon
                                  onClick={() =>
                                    removeProduct(
                                      repairProducts,
                                      setRepairProducts,
                                      product._id,
                                      index
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          {searchedProducts &&
                            searchedProducts.map((prod, index) => (
                              <tr key={prod._id}>
                                <td>{prod.partName}</td>
                                <td>{prod.barCode}</td>
                                <td>{prod.price}</td>
                                <td style={{ cursor: 'pointer' }}>
                                  <ClosedIcon
                                    onClick={() =>
                                      removeProduct(
                                        searchedProducts,
                                        setSearchedProducts,
                                        prod._id,
                                        index
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      )}
                    </table>
                  ) : (
                    <>
                      <table class='blueTable'>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Item ID</th>
                            <th>Price</th>
                            <th></th>
                          </tr>
                        </thead>
                      </table>
                      <div className='no-data' style={{ textAlign: 'center' }}>
                        <p>Search Product by Bar Code to see results</p>
                      </div>
                    </>
                  )}
                </div>
                <div
                  style={{
                    padding: 15,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <MButton
                    text='Paidout'
                    icon={<PaidoutIcon />}
                    bgcolor='#006B8C'
                    color='#fff'
                    onClick={openModal}
                  />
                </div>
              </div>
            </div>
            <div className='right-container'>
              <div className='ber'>
                <div className='table-container'>
                  <table class='blueTable'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Code </th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Discount (%)</th>
                        <th>Type</th>
                        <th>Reason</th>
                        <th></th>
                      </tr>
                    </thead>
                    {repairProducts && repairProducts.length > 0 ? (
                      <tbody>
                        {repairProducts.map((product, index) => (
                          <tr key={index}>
                            <td>{product.partName}</td>
                            <td>{product.barCode}</td>
                            <td>{product.price}</td>
                            <td>
                              <input
                                type='number'
                                placeholder='N/A'
                                min={1}
                                disabled={true}
                                style={{
                                  border: 'none',
                                  outline: 'none',
                                  fontSize: '16px',
                                  width: '100px',
                                  textAlign: 'center',
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type='number'
                                placeholder='N/A'
                                min={1}
                                disabled={true}
                                style={{
                                  border: 'none',
                                  outline: 'none',
                                  fontSize: '16px',
                                  width: '100px',
                                  textAlign: 'center',
                                }}
                              />
                            </td>
                            <td>{product.type}</td>
                            <td>{product.reason}</td>
                            <td style={{ cursor: 'pointer' }}>
                              <ClosedIcon
                                onClick={() =>
                                  removeProduct(
                                    repairProducts,
                                    setRepairProducts,
                                    product._id,
                                    index
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        {searchedProducts &&
                          searchedProducts.map((product, index) => (
                            <tr key={index}>
                              <td>{product.partName}</td>
                              <td>{product.barCode}</td>
                              <td>
                                <td>
                                  {!isNaN(product.numberOfItems) ? (
                                    (() => {
                                      const numberOfItems =
                                        product.numberOfItems;
                                      const discountedPrice =
                                        product.discountedPrice; // Use discountedPrice
                                      const price = product.price;

                                      const totalPrice =
                                        discountedPrice !== undefined
                                          ? discountedPrice * numberOfItems
                                          : price * numberOfItems;

                                      return (
                                        <div>
                                          {discountedPrice !== undefined
                                            ? discountedPrice.toFixed(2)
                                            : totalPrice.toFixed(2)}
                                        </div>
                                      );
                                    })()
                                  ) : (
                                    <div></div>
                                  )}
                                </td>
                              </td>

                              <td>
                                <input
                                  type='number'
                                  placeholder='0'
                                  min={1}
                                  value={product.numberOfItems || ''}
                                  onChange={(e) => {
                                    const enteredQuantity =
                                      parseInt(e.target.value) || 0;

                                    setQuantity(enteredQuantity);

                                    // Check if the entered quantity is greater than the available stock
                                    if (enteredQuantity > searchedItemStock) {
                                      // Display a toast message for the error
                                      toast.error(
                                        'The requested quantity is unavailable due to limited stock. Please select a lower quantity or contact support for assistance.'
                                      );
                                    } else {
                                      const discountedPrice =
                                        calculateDiscountedPrice(
                                          product.price,
                                          discount
                                        );

                                      // Update the quantity if the entered quantity is valid
                                      setSearchedProducts((prevProducts) => {
                                        const updatedProducts =
                                          prevProducts.map((prevProduct) => {
                                            if (
                                              prevProduct.uuid === product.uuid
                                            ) {
                                              return {
                                                ...prevProduct,
                                                numberOfItems: enteredQuantity,
                                                discountedPrice:
                                                  discountedPrice, // Use discountedPrice directly
                                              };
                                            }
                                            return prevProduct;
                                          });
                                        return updatedProducts;
                                      });
                                    }
                                  }}
                                  style={{
                                    border: 'none',
                                    outline: 'none',
                                    fontSize: '16px',
                                    width: '100px',
                                    textAlign: 'center',
                                  }}
                                  disabled={errorDisplayed}
                                />
                              </td>

                              <td>
                                <input
                                  placeholder='0.00%'
                                  type='number'
                                  max={100}
                                  disabled={
                                    product.reason === 'Faulty' ? false : false
                                  }
                                  min={0}
                                  value={product.discount || ''}
                                  onChange={(e) => {
                                    let enteredDiscount =
                                      parseFloat(e.target.value) || 0;

                                    // Ensure the entered value is within the allowed range
                                    enteredDiscount = Math.min(
                                      100,
                                      Math.max(0, enteredDiscount)
                                    );

                                    setDiscount(enteredDiscount);

                                    // Calculate the discounted price
                                    const discountedPrice =
                                      calculateDiscountedPrice(
                                        product.price * quantity,
                                        enteredDiscount
                                      );

                                    // Update the product in the state
                                    setSearchedProducts((prevProducts) => {
                                      const updatedProducts = prevProducts.map(
                                        (prevProduct) => {
                                          if (
                                            prevProduct.uuid === product.uuid
                                          ) {
                                            const updatedProduct = {
                                              ...prevProduct,
                                              discount: enteredDiscount,
                                              discountedPrice: discountedPrice,
                                            };

                                            return updatedProduct;
                                          }
                                          return prevProduct;
                                        }
                                      );

                                      return updatedProducts;
                                    });
                                  }}
                                  style={{
                                    border: 'none',
                                    outline: 'none',
                                    fontSize: '16px',
                                    width: '100px',
                                    textAlign: 'center',
                                  }}
                                />
                              </td>
                              <td>{product.type ?? 'Sold'} </td>
                              <td>
                                {capitalizeFirstLetter(product.reason) ??
                                  'Sold'}{' '}
                              </td>
                              <td style={{ cursor: 'pointer' }}>
                                <ClosedIcon
                                  onClick={() =>
                                    removeProduct(
                                      searchedProducts,
                                      setSearchedProducts,
                                      product._id,
                                      index
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    )}
                  </table>
                </div>
                <div className='sales-container'>
                  <div className='left'>
                    <h3>Total</h3>
                    <p>Discount</p>
                    <p className='net'>Net</p>
                  </div>
                  <div className='right'>
                    <h3>
                      £
                      {repairProducts && repairProducts.length > 0
                        ? calculateTotalPrice(repairProducts)
                        : calculateTotalPrice(searchedProducts)}
                    </h3>
                    <input
                      placeholder='0.00%'
                      type='number'
                      max={100}
                      min={0}
                      disabled={
                        // calculateTotalPrice(searchedProducts) < 0
                        // ||
                        repairProducts?.length > 0 ? true : false
                      }
                      value={totalDiscount || 0}
                      onChange={(e) => {
                        let enteredTotalDiscount =
                          parseFloat(e.target.value) || 0;

                        enteredTotalDiscount = Math.min(
                          100,
                          Math.max(0, enteredTotalDiscount)
                        );

                        setTotalDiscount(enteredTotalDiscount);

                        if (enteredTotalDiscount) {
                          const totalAmount =
                            calculateTotalPrice(searchedProducts);
                          const discountedTotalAmount =
                            totalAmount -
                            totalAmount * (enteredTotalDiscount / 100);

                          const roundedDiscountedTotalAmount =
                            Math.round(discountedTotalAmount * 100) / 100;

                          setDiscountedTotalAmount(
                            roundedDiscountedTotalAmount
                          );
                        } else {
                          setDiscountedTotalAmount(
                            calculateTotalPrice(searchedProducts)
                          );
                        }
                      }}
                    />

                    <p className='net'>£ {netValue}</p>
                  </div>
                </div>
                <div className='sales-row'>
                  <p>Discount</p>
                  <p>{totalDiscount}</p>
                </div>
                <Stack p={'4px 27px'} direction='row' alignItems='center'>
                  <p>Notes</p>
                  <Box
                    width='100%'
                    ml={4}
                    flex={2}
                    sx={{
                      display: 'flex',
                      '& > input': {
                        width: 'inherit',
                        padding: '7px 13px',
                        position: 'relative',
                        borderRadius: '4px',
                        fontSize: 14,
                        outline: 'none',
                        border: '1px solid #f0f0f0',
                        backgroundColor: 'white',
                      },
                    }}
                  >
                    <input
                      type='text'
                      name='name'
                      placeholder='describe something about product...'
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </Box>
                </Stack>
                <div className='button-container'>
                  <MButton
                    text='Cash'
                    icon={<PaidoutIcon />}
                    bgcolor='#006B8C'
                    color='#fff'
                    onClick={() => setShowSelectStaffModal('cash')}
                  />
                  <MButton
                    text='Credit'
                    icon={<PaidoutIcon />}
                    bgcolor='#FF7C5C'
                    color='#fff'
                    onClick={() => setShowSelectStaffModal('credit')}
                  />
                </div>
              </div>
            </div>
          </div>
          <CheckoutModal
            isOpen={isModalOpens}
            onClose={closeModals}
            checkoutCart={(mode) => checkoutCart(mode)}
            showReceipt={showReceipt}
            searchedProducts={searchedProducts}
            totalDiscount={totalDiscount}
            discountedTotalAmount={discountedTotalAmount}
            calculateTotalPrice={(products) => calculateTotalPrice(products)}
            invoice={invoiceNumber}
            userName={userNames}
            paymentMode={paymentMode}
            netDiscount={netDiscount}
          />
          <Modal isOpen={isModalOpen} onClose={closeModal} />
          <RepairCheckoutModal
            isOpen={repairModalOpens}
            userName={userNames}
            onClose={closeRepairModal}
            checkoutCart={(mode) => checkoutCart(mode)}
            showReceipt={showReceipt}
            invoice={invoiceNumber}
            searchedProducts={repairProducts}
            repairSheetReceipt={repairSheetReceipt}
            paymentMode={paymentMode}
          />
          {showSelectStaffModal && (
            <ModalSelection
              isOpen={true}
              onClose={() => setShowSelectStaffModal(false)}
              setSelectedStaffId={(selectedStaff) => {
                setSelectedStaffId(selectedStaff);
                handlePaymentMode(showSelectStaffModal, selectedStaff);
              }}
              setUserName={setUserNames}
            />
          )}
        </div>
      )}
      {/* <Print/> */}
    </>
  );
}

export default HomePage;
