import { Box } from '@mui/material';
import printJS from 'print-js';
import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../Components/Header/svgs/close.svg';
import InvoiceModal from '../Components/InvoiceModal';
import Print from '../Components/Print';

function InvoiceTable({ invoices }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const currentDate = new Date();

  // Format date as DD/MM/YYYY
  const formattedDate = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`;

  // Format time as HH:mm:ss
  const formattedTime = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

  const handlePrintModalOpen = (invoiceId) => () => {
    setPrintModal(true);
    setSelectedInvoice(invoiceId);
  };

  const handlePrintModalClose = () => setPrintModal(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const calculateTotalDiscount = (
    price = 0,
    discount = 0,
    superDiscount = 0
  ) => {
    const discountedPrice =
      price - price * (1 - discount / 100) * (1 - superDiscount / 100);
    const totalDiscount = (discountedPrice / price) * 100;
    return totalDiscount;
  };

  let invoice = selectedInvoice
    ? invoices.filter((el) => el._id === selectedInvoice)?.[0]
    : null;

  return (
    <div className='invoices-container'>
      {invoices.length === 0 ? (
        <div className='stock-table'>
          <>
            <table className='blueTable'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Invoice</th>
                  <th>Stockid</th>
                  <th>Status</th>
                  <th>Price</th>
                  <th>Discount</th>
                  <th>Mode</th>
                  <th>Date</th>
                  <th>Sales Person</th>
                </tr>
              </thead>
            </table>
            <div className='no-data'>
              <p>No data available</p>
            </div>
          </>
        </div>
      ) : (
        invoices.map((invoice) => (
          <div key={invoice._id} className='invoice'>
            <div className='upper-row'>
              <h3>Invoice # {invoice.invoice}</h3>
              <p>Date: {new Date(invoice.createdAt).toLocaleString()}</p>
            </div>
            <div className='stock-table'>
              {invoice.items && invoice.items.length > 0 ? (
                <>
                  <table className='blueTable'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Invoice ID</th>
                        <th>Name</th>
                        <th>CPrice</th>
                        <th>Discount</th>
                        <th>Sell Price</th>
                        <th>Discount Amount</th>
                        <th>Status</th>
                        <th>Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice.items.map((item, itemIndex) => (
                        <tr key={item._id}>
                          <td>{itemIndex + 1}</td>
                          <td>{invoice.invoice}</td>
                          <td>{item.item.partName}</td>
                          <td>£{item.price}</td>
                          <td>
                            {item.isFaulty
                              ? 0
                              : calculateTotalDiscount(
                                  item.price,
                                  item.discount,
                                  invoice.discount
                                ).toFixed(2)}
                            %
                          </td>
                          <td>
                            £
                            {(
                              item.price -
                              (item.price *
                                (calculateTotalDiscount(
                                  item.price,
                                  item.discount,
                                  invoice.discount
                                ) || 0)) /
                                100
                            ).toFixed(2) * item.numberOfItems}
                          </td>
                          <td>
                            £
                            {(
                              (item.price *
                                (calculateTotalDiscount(
                                  item.price,
                                  item.discount,
                                  invoice.discount
                                ) || 0)) /
                              100
                            ).toFixed(2) * item.numberOfItems}
                          </td>
                          <td>{item.item.status || 'Sold'}</td>
                          <td>{item.reason}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <table className='blueTable'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Invoice</th>
                        <th>Stockid</th>
                        <th>Status</th>
                        <th>Price</th>
                        <th>Discount</th>
                        <th>Mode</th>
                        <th>eData</th>
                        <th>User</th>
                      </tr>
                    </thead>
                  </table>
                  <div className='no-data'>
                    <p>No data available</p>
                  </div>
                </>
              )}
            </div>
            <Box
              className='lower-row'
              sx={{
                '& button': {
                  backgroundColor: 'rgb(255, 124, 92)',
                  padding: '10px 20px',
                  color: 'rgb(255, 255, 255)',
                  border: 'none',
                  borderRadius: '8px',
                },
              }}
            >
              <p>Notes: Empty</p>
              <button onClick={handlePrintModalOpen(invoice._id)}>Print</button>
            </Box>
          </div>
        ))
      )}
      <InvoiceModal isOpen={isModalOpen} onClose={closeModal} />
      {printModal && (
        <>
          <div className='modal-overlay'>
            <div className='modal'>
              <div className='Upper-modal-row'>
                <h2>Print Invoice</h2>
                <CloseIcon onClick={handlePrintModalClose} />
              </div>
              <Box
                className='reciept-container'
                sx={{
                  height: '100%',

                  '& #reciept-container': {
                    width: '450px',
                  },
                }}
              >
                <div className='reciept' id='reciept-container'>
                  <h1>Freedom Phones</h1>
                  <div className='reciept-row'>
                    <p>9 stall Street</p>
                    <p>#Invoice : {invoice.invoice}</p>
                  </div>

                  <div className='reciept-row'>
                    <p>Somerset</p>
                    <p>{`${new Date(invoice.createdAt).getDate()}/${
                      new Date(invoice.createdAt).getMonth() + 1
                    }/${new Date(invoice.createdAt).getFullYear()}`}</p>
                    <p>VAT NO: 450 014 056</p>
                  </div>
                  <div className='reciept-row'>
                    <p>BA16 0EN</p>
                    <p>{`${new Date(invoice.createdAt).getHours()}:${new Date(
                      invoice.createdAt
                    ).getMinutes()}:${new Date(
                      invoice.createdAt
                    ).getSeconds()}`}</p>
                    <p style={{ color: 'white' }}>#</p>
                  </div>
                  <div className='reow-reciept'>
                    <h3 className='quantity'>Quantity</h3>
                    <h3 className='product'>Product</h3>
                    <h3 className='price'>Price</h3>
                  </div>
                  {invoice.items.map((item, idx) => (
                    <div className='reow-reciept' key={idx}>
                      <p className='product'>{item.item.partName}</p>
                      <p className='quantity'>{item.numberOfItems}</p>
                      <p className='price'>{item.item.price}</p>
                    </div>
                  ))}
                  <div
                    className='reciept-row'
                    style={{ borderTop: '1px dotted black' }}
                  >
                    <p>Discount</p>
                    <p>
                      {invoice.items[0].isFaulty
                        ? 0
                        : calculateTotalDiscount(
                            invoice.items[0].price,
                            invoice.items[0].discount,
                            invoice.discount
                          ).toFixed(2)}
                      %
                    </p>
                  </div>
                  <div className='reciept-row'>
                    <p>Total </p>
                    <p> {invoice.total}</p>
                  </div>
                  <div className='reciept-row'>
                    <p>Cash/Card</p>
                    <p>{invoice.method}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '350px',
                    }}
                  >
                    <p
                      style={{
                        margin: '5px auto',
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      Thanks for shopping with us
                    </p>
                    <p
                      style={{
                        margin: '5px auto',
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      www.freedomphones.co.uk
                    </p>
                  </div>
                </div>
              </Box>
              <Print
                printReciept={() => {
                  printJS({
                    printable: 'reciept-container',
                    type: 'html',
                    targetStyles: ['*'], // Apply styles to the entire document
                  });
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default InvoiceTable;
