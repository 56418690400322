import React, { useEffect, useState } from "react";
import { Button, ButtonTags } from "../Buttons";
import "../../Styles/Header/Header.css";
import { LuLogOut } from "react-icons/lu";
import { MdOutlineRefresh } from "react-icons/md";
import { Link, useNavigate, useRoutes } from "react-router-dom";
import { ReactComponent as SalesBlackIcon } from "../../Pages/Svg/salesblack.svg";
import { ReactComponent as StocksBlackIcon } from "../../Pages/Svg/stocksblack.svg";
import { ReactComponent as InvoiceBlackIcon } from "../../Pages/Svg/invoiceblack.svg";
import { ReactComponent as RefreshIcon } from "./svgs/refresh.svg";
import { ReactComponent as DashboardIcon } from "./svgs/dashboard.svg";
import { ReactComponent as Lock } from "./svgs/lock.svg";
import logo from "../../Images/login.png";
import { ReactComponent as DateIcon } from "./svgs/date.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux-store/userSlicer";
import { logoutUser } from "../../Redux-store/userActions";
import { FaUserGroup } from "react-icons/fa6";
import ModalSelection from "./ModalSelection";
import axios from "axios";

function Header({
  selectedStaffId,
  setSelectedStaffId,
  handleRefresh,
  setUserNames,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleLogout = () => {
    dispatch(logoutUser());
    localStorage.removeItem("user");
    localStorage.removeItem("selectedStaffId");
    localStorage.removeItem("token");
    navigate("/login");
  };
  const [userName, setUserName] = useState("");

  const loggedInUserShopId =
    JSON.parse(localStorage.getItem("user"))?._id ?? undefined;
  const fetchUsername = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/staffMember/${userId}?shop=${loggedInUserShopId}`
      );
      const username = response.data.data.name;
      setUserName(username); // Set the selected username
      setUserNames(username);
    } catch (error) {
      console.error(
        `Error while fetching username for user ID ${userId}:`,
        error
      );
    }
  };

  useEffect(() => {
    if (selectedStaffId) fetchUsername(selectedStaffId);
  }, [selectedStaffId]);

  return (
    <div className="header">
      <div className="upper">
        <img src={logo} />
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Link to="/sales">
            <ButtonTags
              text="Sales Report"
              icon={<StocksBlackIcon />}
              bgcolor="#F3F3F3"
              color="#000"
            />
          </Link>
          <Link to="/stock">
            <ButtonTags
              text="Stock Report"
              icon={<SalesBlackIcon />}
              bgcolor="#F3F3F3"
              color="#000"
            />
          </Link>
          <Link to="/invoices">
            <ButtonTags
              text="Invoices"
              icon={<InvoiceBlackIcon />}
              bgcolor="#F3F3F3"
              color="#000"
            />
          </Link>
          <Button
            text="Logout"
            icon={<LuLogOut />}
            bgcolor="#FF7C5C"
            hoverColor="rgb(242 174 157)"
            color="#fff"
            onClick={handleLogout}
          />
        </div>
      </div>
      <div className="lower">
        <div className="tags-container">
          <ButtonTags
            text="Refresh"
            icon={<DashboardIcon />}
            // icon={<MdOutlineRefresh />}
            bgcolor="#F3F3F3"
            color="#000"
            onClick={handleRefresh}
          />
          <Link to="/home">
            <ButtonTags
              text="Open Till"
              icon={<Lock />}
              bgcolor="#F3F3F3"
              color="#000"
            />
          </Link>
          <Link to="/dashboard">
            <ButtonTags
              text="Dashboard"
              icon={<RefreshIcon />}
              bgcolor="#F3F3F3"
              color="#000"
            />
          </Link>
        </div>
      </div>
      {showModal && (
        <ModalSelection
          isOpen={true}
          onClose={() => {
            setShowModal(false);
          }}
          setSelectedStaffId={setSelectedStaffId}
          setUserName={setUserName}
        />
      )}
    </div>
  );
}

export default Header;
