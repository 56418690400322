import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

/**When we refresh page the token inside redux is null which makes the condition true and it is redirected to Login page but on login page in App.js if we have token then it should be redirected to Homepage and at that time token is refilled with localstorage token. That's why we are using token from localstorage here. */

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  let location = useLocation();

  if (!token) {
    if (location.pathname !== "/login") {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }

  return children;
};

export default ProtectedRoute;
