import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import * as React from 'react';

export default function DatePicker({ date, setDate, onClose }) {
  const handleDateChange = (newDate) => {
    // const formattedDate = dayjs(newDate).format("YYYY-MM-DD");
    console.log('newDate', newDate);
    setDate(newDate);
    onClose();
  };

  return (
    <div
      style={{ position: 'absolute', right: '0', zIndex: '111111111111111111' }}
    >
      {/* <DemoItem> */}
      <StaticDatePicker
        onClose={onClose}
        onAccept={(newDate) => {
          setDate(dayjs(newDate).format('YYYY-MM-DD'));
        }}
        value={date}
        actionBar={false}
        onChange={handleDateChange}
        orientation='portrait'
      />
      {/* </DemoItem> */}
    </div>
  );
}
