import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import "../Styles/Home/Home.css";
import { MButton } from "../Components/Buttons";
import { ReactComponent as ReportIcon } from "./Svg/reportd.svg";
import { ReactComponent as SalesIcon } from "./Svg/salesd.svg";
import { ReactComponent as InvoicesIcon } from "./Svg/invoided.svg";
import { ReactComponent as TillIcon } from "./Svg/tilld.svg";
import { ReactComponent as PrintIcon } from "./Svg/printd.svg";
import { ReactComponent as SettingsIcon } from "./Svg/settingd.svg";
import Modal from "../Components/Modal";
import Spinner from "../Components/Spinner";
import Box from "../Components/Box";
import { Link } from "react-router-dom";
function Dashboard() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Header />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="homepage">
          <div className="body-layout">
            <div className="boxes-container">
              <Link to="/sales">
                {" "}
                <Box
                  name="Sales Report"
                  icon={<ReportIcon />}
                  time="Last Viewed 12 Hrs ago"
                />
              </Link>
              <Link to="/stock">
                {" "}
                <Box
                  name="Stock Report"
                  icon={<SalesIcon />}
                  time="Last Viewed 12 Hrs ago"
                />
              </Link>
              <Link to="/invoices">
                {" "}
                <Box
                  name="Invoices"
                  icon={<InvoicesIcon />}
                  time="Last Viewed 12 Hrs ago"
                />
              </Link>
              <Link to="">
                {" "}
                <Box
                  name="Till Report"
                  icon={<TillIcon />}
                  time="Last Viewed 12 Hrs ago"
                />
              </Link>
              <Link to="">
                {" "}
                <Box
                  name="Printer"
                  icon={<PrintIcon />}
                  time="Last Viewed 12 Hrs ago"
                />
              </Link>
              {/* <Link to="/settings">
                {" "}
                <Box
                  name="Settings"
                  icon={<SettingsIcon />}
                  time="Last Viewed 12 Hrs ago"
                />
              </Link> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
