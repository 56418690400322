import React, { useState, useEffect } from "react";
import Input from "../Components/Input";
import { Link, useNavigate } from "react-router-dom";
import "../Styles/Login.css";
import { ReactComponent as UserIcon } from "./Svg/frey.svg";
import { ReactComponent as ThreeIcon } from "./Svg/threes.svg";
import logo from "../Images/login.png";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../Redux-store/userActions";

function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [screenType, setScreenType] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.user);

  useEffect(() => {
    // Check local storage for saved credentials when the component mounts
    const storedUserName = localStorage.getItem("rememberedUserName");
    const storedRememberMe = localStorage.getItem("rememberMe");

    if (storedRememberMe === "true" && storedUserName) {
      setUserName(storedUserName);
      setRememberMe(true);
    }
  }, []);

  const handleLoginEvent = async (e, type) => {
    setScreenType(type);
    e.preventDefault();

    let userCredentials = {
      email: userName,
      password,
    };

    try {
      await dispatch(loginUser(userCredentials));

      if (rememberMe) {
        // Save the user credentials in local storage if "Remember Me" is checked
        localStorage.setItem("rememberedUserName", userName);
        localStorage.setItem("rememberMe", "true");
      } else {
        // Remove saved credentials from local storage if "Remember Me" is not checked
        localStorage.removeItem("rememberedUserName");
        localStorage.removeItem("rememberMe");
      }

      setUserName("");
      setPassword("");
      setRememberMe(false);

      type === "purchase"
        ? navigate("/buyer/price-check")
        : navigate("/homepage");
    } catch (error) {
      // setErrorMessage("Login Failed!. Please check your credentials!");
    }
  };

  const userIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M7 6.66667C7.92826 6.66667 8.8185 6.31548 9.47487 5.69036C10.1313 5.06523 10.5 4.21739 10.5 3.33333C10.5 2.44928 10.1313 1.60143 9.47487 0.97631C8.8185 0.351189 7.92826 0 7 0C6.07174 0 5.1815 0.351189 4.52513 0.97631C3.86875 1.60143 3.5 2.44928 3.5 3.33333C3.5 4.21739 3.86875 5.06523 4.52513 5.69036C5.1815 6.31548 6.07174 6.66667 7 6.66667ZM7 8.33333C5.64143 8.33236 4.30133 8.63314 3.08619 9.21178C1.87104 9.79043 0.814328 10.631 0 11.6667C0.814328 12.7024 1.87104 13.5429 3.08619 14.1215C4.30133 14.7002 5.64143 15.001 7 15C8.35857 15.001 9.69867 14.7002 10.9138 14.1215C12.129 13.5429 13.1857 12.7024 14 11.6667C13.1857 10.631 12.129 9.79043 10.9138 9.21178C9.69867 8.63314 8.35857 8.33236 7 8.33333Z"
        fill="#444444"
        fill-opacity="0.41"
      />
    </svg>
  );
  const passwordIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="13"
      viewBox="0 0 24 13"
      fill="none"
    >
      <path
        d="M24 2C24 1.1 23.4 0.5 22.5 0.5H1.5C0.6 0.5 0 1.1 0 2V11C0 11.9 0.6 12.5 1.5 12.5H22.5C23.4 12.5 24 11.9 24 11V2ZM22.5 11H1.5V2H22.5V11Z"
        fill="#444444"
        fill-opacity="0.41"
      />
      <path
        d="M9 7C9 7.39782 8.84196 7.77936 8.56066 8.06066C8.27936 8.34196 7.89782 8.5 7.5 8.5C7.10218 8.5 6.72064 8.34196 6.43934 8.06066C6.15804 7.77936 6 7.39782 6 7C6 6.60218 6.15804 6.22064 6.43934 5.93934C6.72064 5.65804 7.10218 5.5 7.5 5.5C7.89782 5.5 8.27936 5.65804 8.56066 5.93934C8.84196 6.22064 9 6.60218 9 7ZM13.5 7C13.5 7.39782 13.342 7.77936 13.0607 8.06066C12.7794 8.34196 12.3978 8.5 12 8.5C11.6022 8.5 11.2206 8.34196 10.9393 8.06066C10.658 7.77936 10.5 7.39782 10.5 7C10.5 6.60218 10.658 6.22064 10.9393 5.93934C11.2206 5.65804 11.6022 5.5 12 5.5C12.3978 5.5 12.7794 5.65804 13.0607 5.93934C13.342 6.22064 13.5 6.60218 13.5 7ZM18 7C18 7.39782 17.842 7.77936 17.5607 8.06066C17.2794 8.34196 16.8978 8.5 16.5 8.5C16.1022 8.5 15.7206 8.34196 15.4393 8.06066C15.158 7.77936 15 7.39782 15 7C15 6.60218 15.158 6.22064 15.4393 5.93934C15.7206 5.65804 16.1022 5.5 16.5 5.5C16.8978 5.5 17.2794 5.65804 17.5607 5.93934C17.842 6.22064 18 6.60218 18 7Z"
        fill="#444444"
        fill-opacity="0.41"
      />
    </svg>
  );
  return (
    <div className="loginPage">
      <div className="container">
        <figure>
          <img src={logo} alt="logo" />
        </figure>
        <Input
          type="text"
          placeholder="Enter Username"
          icon={userIcon}
          name="userName"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Enter Password"
          name="password"
          icon={passwordIcon}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="remember-container">
          <div className="remember">
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />

            <p>Remember me</p>
          </div>
        </div>
        {error && <p className="error-message">{error}</p>}

        <Link to="/">
          <button onClick={(e) => handleLoginEvent(e, "login")}>
            {loading && screenType == "login" ? "...Loading" : "Login"}
          </button>
        </Link>
        <Link to="/">
          <button onClick={(e) => handleLoginEvent(e, "purchase")}>
            {loading && screenType == "purchase" ? "...Loading" : "Purchase"}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Login;
