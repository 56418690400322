import React, { useState } from "react";
import "../../Styles/InputCard/InputCard.css";
import { ReactComponent as SearchIcon } from "../Header/svgs/search.svg";

export function InputSelectionCard({
  heading,
  options,
  selectedOption,
  onOptionChange,
  onOptionClick,
}) {
  return (
    <div className="card">
      {heading && <h3>{heading}</h3>}
      <div className="row-rwss">
        {options.map((option, index) => (
          <div className="checkbox-card" key={index}>
            <label className="checkbox-container">
              <label className="rounded-checkbox">
                <input 
                  type="radio"
                  value={option.value} // Value for the option
                  checked={selectedOption === option.value}
                  onChange={onOptionChange}
                  onClick={onOptionClick}
                />
                <span className="checkmark"></span>
              </label>
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
export function InputSearchBar({
  heading,
  placeholder,
  value,
  onKeyDown,
  onChangeHandler,
}) {
  return (
    <div className="card">
      {heading && <h3>{heading}</h3>}
      <div className="row-rws">
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          className="product-bar"
          onChange={onChangeHandler}
          onKeyDown={onKeyDown} // Listen for Enter key press
        />
      </div>
    </div>
  );
}
