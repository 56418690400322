import React, { useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { ReactComponent as BlackIcon } from '../../Images/blackclose.svg';
import '../../Styles/SelectOption/SelecOption.css';

import profile from '../../Images/dp.png';

export function StaffSelect({ options, onSelect, name, icon, onClose }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [showInput, setShowInput] = useState(false); // State to control input field visibility
  const [searchQuery, setSearchQuery] = useState('');

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
    setShowInput(true); // Show input field when button is clicked
  };

  const handleOptionClick = (option) => {
    setSelectedValue(option.label);
    setIsOpen(false);
    onSelect(option.value);
    onClose();
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value); // Update search query
    setSelectedValue(''); // Clear selected value when typing in the search input
  };

  return (
    <div
      className='custom-select'
      style={{
        height: '100%',
        // padding: `0px 0px ${isOpen ? '20px' : '0'} 0px`,
        background: '#f5f5f5',
        boxShadow: '0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset',
        alignItems: 'stretch',
      }}
    >
      {/* {isOpen == false && ( */}
      <button
        onClick={handleButtonClick}
        style={{
          paddingInline: '23px',
          background: 'transparent',
          height: '100%',
        }}
      >
        {name === 'Select Staff' && selectedValue === '' ? (
          <p style={{ margin: 0 }}> {selectedValue || name} </p>
        ) : (
          <p style={{ margin: 0 }}>
            <img src={profile} alt={'user'} height={'22px'} width={'22px'} />
            {selectedValue || name}{' '}
            <BlackIcon
              onClick={() => {
                setIsOpen(!isOpen);
                setSelectedValue('');
                onSelect('');
              }}
            />
          </p>
        )}
        <FaAngleDown />
      </button>
      {isOpen && (
        <>
          <ul className='options' style={{ height: '300px', zIndex: 100 }}>
            {filteredOptions?.map((option) => (
              <li key={option.value} onClick={() => handleOptionClick(option)}>
                <p>
                  <img
                    src={profile}
                    height={'22px'}
                    width={'22px'}
                    alt={option.label}
                  />{' '}
                  {option.label}{' '}
                </p>{' '}
                {option.label !== selectedValue && (
                  <span className='bordercircle' style={{ width: '30px' }}>
                    Select
                  </span>
                )}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
