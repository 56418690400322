import React, { useEffect, useState } from "react";
import axios from "axios";
import "../Styles/StockReport/StockReport.css";
import NavHeader from "../Components/Header/NavHeader";
import SearchInput from "../Components/Search";
import InvoiceTable from "./InvoiceTable";
import Spinner from "../Components/Spinner";
import { debounce } from "lodash";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

function Invoices() {
  const [isLoading, setIsLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [searchParams, setSearchParams] = useState({
    invoice: "",
    item: "",
    dateFrom: "",
    dateTo: "",
  });

  const loggedInUserShopId =
    JSON.parse(localStorage.getItem("user"))?._id ?? undefined;

  useEffect(() => {
    fetchInvoiceData();
  }, []);

  const debouncedFetchInvoiceData = debounce(() => {
    fetchInvoiceData();
  }, 500);

  const handleSearch = () => {
    debouncedFetchInvoiceData();
  };

  useEffect(() => {
    handleSearch();
  }, [searchParams]);

  const fetchInvoiceData = async () => {
    try {
      const queryParams = [];

      // Include the search terms in the query parameters
      if (searchParams.invoice) {
        queryParams.push(`invoice=${searchParams.invoice}`);
      }
      if (searchParams.item) {
        queryParams.push(`item=${searchParams.item}`);
      }
      if (searchParams.dateFrom) {
        // Convert dateFrom to the desired format (dd-MM-yyyy)
        const dateFrom = new Date(searchParams.dateFrom);
        const formattedDateFrom = `${dateFrom.getDate()}-${
          dateFrom.getMonth() + 1
        }-${dateFrom.getFullYear()}`;
        queryParams.push(`dateFrom=${formattedDateFrom}`);
      } else {
        queryParams.push(`dateFrom=`);
      }
      if (searchParams.dateTo) {
        // Convert dateTo to the desired format (dd-MM-yyyy)
        const dateTo = new Date(searchParams.dateTo);
        const formattedDateTo = `${dateTo.getDate()}-${
          dateTo.getMonth() + 1
        }-${dateTo.getFullYear()}`;
        queryParams.push(`dateTo=${formattedDateTo}`);
      } else {
        queryParams.push(`dateTo=`);
      }

      // Join the query parameters with '&' to form the complete query string
      const queryString = queryParams.join("&");

      // Construct the API URL with query parameters
      const apiUrl = queryString
        ? `/api/v1/invoice?${queryString}&shop=${loggedInUserShopId}`
        : `/api/v1/invoice?shop=${loggedInUserShopId}`;

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${apiUrl}`
      );
      setInvoices(response.data.data);
      // Handle the response data here
    } catch (error) {
      console.error("Error while fetching invoice data: ", error);
    }
  };

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  const datePickerStyles = {
    input: {
      flex: "1 1",
      background: "#f5f5f5",
      padding: "8px", // Adjust padding as needed
      borderRadius: "10px",
      outline: "none",
      color: "#444",
      fontFamily: "Inter Tight",
      fontWeight: "400",
      lineHeight: "normal",
    },
    // Add more styles as needed
  };

  return (
    <>
      <NavHeader navs="3" />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="stock-page-container">
          <div className="stocks-row">
            <SearchInput
              placeholder="Search Invoice"
              type="text"
              value={searchParams.invoice}
              onChange={(e) =>
                setSearchParams({ ...searchParams, invoice: e.target.value })
              }
            />
            <SearchInput
              placeholder="Search Item"
              value={searchParams.item}
              type={"text"}
              onChange={(e) =>
                setSearchParams({ ...searchParams, item: e.target.value })
              }
            />
            <DesktopDatePicker
              placeholder="Search Date From"
              value={searchParams.dateFrom}
              inputFormat={() => "From"}
              onChange={(date) =>
                setSearchParams({ ...searchParams, dateFrom: date })
              }
              TextFieldComponent={(props) => (
                <input
                  {...props}
                  style={datePickerStyles}
                  placeholder="Custom Placeholder"
                />
              )}
              InputLabelProps={{
                shrink: true, // Ensure the label is not floating
              }}
            />

            <DesktopDatePicker
              placeholder="Search Date To"
              value={searchParams.dateTo}
              onChange={(date) =>
                setSearchParams({ ...searchParams, dateTo: date })
              }
              TextFieldComponent={(props) => (
                <input
                  {...props}
                  style={datePickerStyles}
                  placeholder="Custom Placeholder"
                />
              )}
            />
            {searchParams.dateTo !== "" ||
              (searchParams.dateFrom !== "" && (
                <button
                  onClick={() => {
                    setSearchParams({
                      ...searchParams,
                      dateTo: "",
                      dateFrom: "",
                    });
                  }}
                  style={{ border: "none", background: "transparent" }}
                >
                  ❌
                </button>
              ))}
            <button className="search-button" onClick={handleSearch}>
              Search
            </button>
          </div>
          <InvoiceTable invoices={invoices} />
        </div>
      )}
    </>
  );
}

export default Invoices;
