import axios from 'axios';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DiscountModal from '../Components/DiscountModal';
import LoadingIcon from '../Components/LoadingIcon';
import SearchInput from '../Components/Search';
import { Spinnie } from '../Components/Spinner';
import '../Styles/RepairSheet/RepairListing.css';
import ListingBox from './ListingBox';

function RepairListing({ nav, fromDate, toDate }) {
  const [repairList, setRepairList] = useState([]);
  // const [searchTerm, setSearchTerm] = useState('');
  const [searchItem, setSearchItem] = useState({
    invoice: '',
    barCode: '',
    customerName: '',
    makeModel: '',
  });
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [repairId, setRepairId] = useState(null);

  const loggedInUserShopId =
    JSON.parse(localStorage.getItem('user'))?._id ?? undefined;

  const [page, setPage] = useState(1);
  const debouncedFetchRepairList = debounce(() => {
    fetchRepairList();
  }, 500);

  useEffect(() => {
    fetchRepairStats();
    fetchRepairList();
  }, [fromDate, toDate]);

  useEffect(() => {
    debouncedFetchRepairList();
  }, [searchItem]);

  const handleTableScroll = (event) => {
    const { target } = event;
    if (
      Math.floor(target.scrollHeight - target.scrollTop) ==
        target.clientHeight &&
      !loading
    ) {
      setPage((prevPage) => prevPage + 1);
      fetchRepairLists(page);
    }
  };
  const filterValidFields = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value) acc['search'] = value;
      return acc;
    }, {});
  };
  const fetchRepairList = async () => {
    try {
      setLoading(true);
      const searchTxt = filterValidFields(searchItem);
      let url = !Object.keys(searchTxt).length
        ? `/api/v1/repair`
        : `/api/v1/repair?search=${searchTxt.search}`;
      url += `${url.includes('?') ? '&' : '?'}shop=${loggedInUserShopId}`;
      if (fromDate && toDate) {
        url += `${url.includes('?') ? '&' : '?'}dateFrom=${fromDate.format(
          'MM-DD-YYYY'
        )}&dateTo=${toDate.format('MM-DD-YYYY')}`;
      }
      console.log('url', url);
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }${url}&pageNumber=${page}&records=${20}`
      );
      const repairData = response.data.data;
      setRepairList(repairData);
    } catch (error) {
      console.error('Error while fetching repair entries: ', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchRepairLists = async (page) => {
    try {
      // setLoading(true);
      const searchTxt = filterValidFields(searchItem);
      let url = !Object.keys(searchTxt).length
        ? `/api/v1/repair`
        : `/api/v1/repair?search=${searchTxt.search}`;
      url += `${url.includes('?') ? '&' : '?'}shop=${loggedInUserShopId}`;

      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/${url}&pageNumber=${page}&recordsPerPage=${20}`
      );
      const repairData = response.data.data;

      // Check if repairData is not empty before updating the state
      if (repairData.length > 0) {
        setRepairList(repairData);
      }
    } catch (error) {
      console.error('Error while fetching repair entries: ', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchRepairStats = async () => {
    try {
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/repair/stat?shop=${loggedInUserShopId}`;

      if (fromDate && toDate) {
        apiUrl += `&dateFrom=${fromDate.format(
          'MM-DD-YYYY'
        )}&dateTo=${toDate.format('MM-DD-YYYY')}`;
      }
      const response = await axios.get(apiUrl);
      const statsData = response.data.data[0];

      // Update the state with the fetched repair stats
    } catch (error) {
      console.error('Error while fetching repair stats: ', error);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setRepairId(null);
    setIsModalOpen(false);
  };

  const handleSearcTxt = (e) => {
    const { name, value } = e.target;
    setSearchItem({
      invoice: name === 'invoice' ? value : '',
      barCode: name === 'barCode' ? value : '',
      customerName: name === 'customerName' ? value : '',
      makeModel: name === 'makeModel' ? value : '',
    });
  };

  return (
    <div className='repair-listing'>
      {nav && (
        <div className='search-row'>
          <SearchInput
            placeholder='Search By Invoice No'
            searchTerm={searchItem.invoice}
            onChange={handleSearcTxt}
            name='invoice'
          />
          <SearchInput
            placeholder='Search By Customer Name'
            searchTerm={searchItem.customerName}
            onChange={handleSearcTxt}
            name='customerName'
          />
          <SearchInput
            placeholder='Search By Make & Model'
            searchTerm={searchItem.makeModel}
            onChange={handleSearcTxt}
            name='makeModel'
          />
          <SearchInput
            placeholder='Search by Barcode'
            searchTerm={searchItem.barCode}
            onChange={handleSearcTxt}
            name='barCode'
          />
          {/* <button className='search-button'>Search</button> */}
        </div>
      )}
      <div className='repair-listing-table'>
        {loading ? (
          <>
            <div className='sales-table'>
              {' '}
              <table className='blueTable'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Invoice</th>
                    <th>Make & Model</th>
                    <th>Customer Name</th>
                    <th>Total Price</th>
                    <th>Initial deposit</th>
                    <th>Remaining deposit</th>
                    <th>Final Deposit</th>
                    <th>Initial deposit Method</th>
                    <th>Final Deposit Method</th>
                    <th>Status</th>
                    <th>Discount</th>
                  </tr>
                </thead>
              </table>
            </div>
            <Spinnie />
          </>
        ) : repairList && repairList.length > 0 ? (
          <div
            style={{
              width: ' 100%',
              overflowY: 'scroll',
              maxHeight: '300px',
              marginBottom: '50px',
            }}
            onScroll={handleTableScroll}
          >
            <table className='blueTable'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Invoice</th>
                  <th>Make & Model</th>
                  <th>Customer Name</th>
                  <th>Total Price</th>
                  <th>Initial deposit</th>
                  <th>Remaining deposit</th>
                  <th>Final Deposit</th>
                  <th>Initial deposit Method</th>
                  <th>Final Deposit Method</th>
                  <th>Status</th>
                  <th>Discount</th>
                </tr>
              </thead>
              <tbody>
                {repairList
                  // .filter((item) => item.sentToLab === false)
                  .map((repair, index) => (
                    <tr key={repair._id}>
                      <td>{new Date(repair.date).toLocaleDateString()}</td>
                      <td>{repair.invoice}</td>
                      <td>{repair.makeAndModel}</td>
                      <td>{repair.customerName}</td>

                      <td>{repair.charges}</td>
                      <td>{repair.deposit}</td>
                      <td>{repair.balance}</td>
                      <td>{repair.finalDeposit ? repair.finalDeposit : '0'}</td>
                      <td>
                        {repair?.isInitialDeposit === false &&
                          repair.initialDepositMethod === '' &&
                          'No Initial deposit'}
                        {repair.isInitialDeposit === false &&
                          repair.initialDepositMethod === 'cash' &&
                          'Paid by Cash'}
                        {repair.isInitialDeposit === false &&
                          repair.initialDepositMethod === 'credit' &&
                          'Paid by Credit'}
                        {repair.isInitialDeposit === true &&
                          repair.initialDepositMethod === '' &&
                          'To be paid'}
                      </td>
                      <td>
                        {repair.isFinalDeposit === false &&
                          repair.finalDepositMethod === '' &&
                          'No Initial deposit'}
                        {repair.isFinalDeposit === false &&
                          repair.finalDepositMethod === 'cash' &&
                          'Paid by Cash'}
                        {repair.isFinalDeposit === false &&
                          repair.finalDepositMethod === 'credit' &&
                          'Paid by Credit'}
                        {repair.isFinalDeposit === true &&
                          repair.finalDepositMethod === '' &&
                          'To be paid'}
                      </td>
                      <td>{repair.status}</td>
                      <td>{repair.discount}%</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <>
            <table className='blueTable'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Barcode</th>
                  <th>Make & Model</th>
                  <th>Customer Name</th>
                  <th>Status</th>
                  <th style={{ width: '10%' }}>Actions</th>
                </tr>
              </thead>
            </table>
            <div className='no-data'>
              <p>No data available</p>
            </div>
          </>
        )}
      </div>
      <DiscountModal
        repairId={repairId}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
}

export default RepairListing;
