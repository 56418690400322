import React, { useState, useEffect } from "react";
import { StaffSelect } from "../SelectOption/StaffSelect";
import axios from "axios";
import { ReactComponent as CloseIcon } from "../Header/svgs/close.svg";
import { toast } from "react-toastify";

function ModalSelection({ setSelectedStaffId, isOpen, onClose, setUserName }) {
  const [staffMembers, setStaffMembers] = useState([]);
  const loggedInUserShopId =
    JSON.parse(localStorage.getItem("user"))?._id ?? undefined;
  useEffect(() => {
    // Make an API call to fetch staff members
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/staffMember?shop=${loggedInUserShopId}`
      )
      .then((response) => {
        const staffData = response.data.data.staffMembers;
        const staffOptions = staffData.map((staff) => ({
          label: staff.name,
          value: staff._id,
        }));
        setStaffMembers(staffOptions);
      })
      .catch((error) => {
        console.error("Error fetching staff members:", error);
      });
  }, []);

  const fetchUsername = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/staffMember/${userId}?shop=${loggedInUserShopId}`
      );
      return response.data.data.name;
    } catch (error) {
      console.error(
        `Error while fetching username for user ID ${userId}:`,
        error
      );
      return ""; // Return an empty string in case of an error
    }
  };
  const handleSelect = async (selectedValue) => {
    try {
      // Fetch the username based on the selected staff ID
      const username = await fetchUsername(selectedValue);
      setSelectedStaffId(selectedValue);
      localStorage.setItem("selectedStaffId", selectedValue);

      setUserName(username);

      // Save the selected staff ID in localStorage
      localStorage.setItem("selectedStaffId", selectedValue);
    } catch (error) {
      console.error("Error while fetching username:", error);
      toast.error("Error fetching username. Please try again.");
    }
  };
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="Upper-modal-row">
          <h2>Select Staff</h2>
          <CloseIcon onClick={onClose} />
        </div>
        <StaffSelect
          options={staffMembers}
          onSelect={handleSelect}
          name="Select Staff"
          onClose={onClose}
        />
      </div>
    </div>
  );
}

export default ModalSelection;
