import React, { useState } from 'react';
import '../Styles/Modal.css'; // Import your CSS for modal styling
import {ReactComponent as CloseIcon} from '../Components/Header/svgs/close.svg'
import Input from './Input';
import {ReactComponent as PrinterIcon} from '../Pages/Svg/printer.svg'
import { CheckBox } from '../Pages/InputBox';
import {ReactComponent as DownloadIcon} from '../Pages/Svg/download.svg'
import {CustomSelectTwo} from './SelectOption/SelectOption';
import {ReactComponent as BlackPrinter} from '../Pages/Svg/printerb.svg'
function InvoiceModal({ isOpen, onClose, children }) {
    const [selectedOption, setSelectedOption] = useState('option1'); // Set the default selected option
  if (!isOpen) return null;
  const options = [
    { value: "option1", label: "Option1" },
    { value: "option2", label: "Option2" },
    { value: "option3", label: "Option3" },
  ];
  const handleSelect = (value) => {
    setSelectedOption(value);
  };
  

  const handleSelectChange = (event) => {
    // Update the selectedOption state when the user selects an option
    setSelectedOption(event.target.value);
  };
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className='Upper-modal-row'>
               <h2>Printer Settings</h2> 
               <CloseIcon onClick={onClose}/>
        </div>
      
        <div className='columner'>
      <h4>Connnected Devices</h4>
      <div className='rown'>
        <div className='input-boxer'>
        <CustomSelectTwo  options={options} onSelect={handleSelect}  name="Select Printer" />
        </div>
        <button className="button-mediur"  >
          <p className="button-text">
            <span className="button-icon"><PrinterIcon/></span> 
            Test Selected Device
          </p>
        </button>
      </div>
      <h4>Saved Printers</h4>
      <div className='rown'>
        <div className='input-boxer'>
          <div className='youu'>
            <BlackPrinter/> <p>{selectedOption}</p>
          </div>
        </div>
        <button className="button-mediur"  >
          <p className="button-text">
            <span className="button-icon"><PrinterIcon/></span> 
            Test Saved Printer
          </p>
        </button>
      </div>
      <button className='modal-button'>   <p className="button-text">
            <span className="button-icon"><DownloadIcon/></span> 
            Save
          </p></button>
        </div>
        </div>
    </div>
  );
}

export default InvoiceModal;